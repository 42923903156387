* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.imageSection {
  position: relative;
  width: 100%;
  padding: 5rem 0;
  background-color: #ff0000;
}
.maincontent {
  display: flex;
  max-width: 1140px;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
}

.content {
  position: relative;
  z-index: 1;
  max-width: 50%;
  width: 100%;
  color: white;
  text-align: center;
  padding: 0px 20px 0px 0px;
}

.heading1 h1 {
  color: #ffffff;
  font-size: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0.8rem;
}
.heading2 h2 {
  color: #ffffff;
  font-size: 48px;
  font-weight: 800;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
  margin-bottom: 20px;
}
#btn {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 15px 60px 15px 60px;
  transition: color 0.3s, border-color 0.3s, transform 0.3s; /* Added transform to transition */
}

#btn:hover {
  background-color: #500202;
  transform: scale(1.1); /* Grow size by 10% on hover */
  animation: bounce 0.3s infinite alternate; /* Applying bouncing animation */
}

@keyframes bounce {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.05); /* Slightly bigger size */
  }
}

.video {
  position: relative;
  
  max-width: 50%;
  width: 100%;
  text-align: center;
  padding: 0px 150px 0px 150px;
}

.video img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 1020px) {
  .content {
    padding-top: 75px;
  }
  .heading1 h1 {
    font-size: 0.9em;
    line-height: 1.7em;
  }
  .heading2 h2 {
    font-size: 27px;
  }
  .video {
    padding: 75px;
  }
}
@media (max-width: 767px) {
  .content {
    padding-top: 0px;
  }

  .maincontent {
    display: block;
  }
  .heading1 {
    padding-top: 1rem;
  }
  .content {
    max-width: 100%;
  }
  .video {
    max-width: 100%;
  }
}
@media (max-width: 350px) {
  .video {
    padding: 40px;
  }
}
/* /////////////////////////////////////////////////////// */
.SimulatedBodySection {
  display: flex;
  width: 100%;
  padding: 2rem 2rem;
  text-align: left;
  font-family: "Roboto", Sans-serif;
}
.SimulatedBodydetails {
  width: 56%;
  margin: 0% 2% 0% 2%;
}
.SimulatedBodydetails h1 {
  color: #eb0009;
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.SimulatedBodydetails p {
  line-height: 28px;

  color: #000000;
  font-family: "Roboto", Sans-serif;
  font-size: 1.2em;
  font-weight: 400;
}

.pupilsImg {
  width: 44%;
  padding: 10px;
}
.pupilsImg img {
  width: 100%;
  height: auto;
}
@media (max-width: 1020px) {
  .SimulatedBodydetails h1 {
    font-size: 32px;
  }

  .SimulatedBodydetails {
    width: 60%;
  }
  .pupilsImg {
    width: 40%;
  }
}
@media (max-width: 777px) {
  .SimulatedBodySection {
    display: block;
  }
  .SimulatedBodydetails {
    width: 100%;
    margin: 0;
  }
  .pupilsImg {
    width: 100%;
  }
  .SimulatedBodydetails h1 {
    font-size: 55px;
  }
}
@media (max-width: 468px) {
  .SimulatedBodydetails h1 {
    font-size: 32px;
  }
}
/*/////////////////////////////////////////// Youtube video section/////////////////////////////////////// */

.driverSection {
  background-color: #ffd840;
}
.videoSection {
  max-width: 1140px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.videoSectionleft {
  padding: 20px;
}

.videoSectionright {
  padding: 20px;
}

.videoSectionleft h1 {
  color: #eb0009;
  font-size: 50px;
  font-weight: 600;
  font-family: "Roboto", Sans-serif;
}

.videoSectionleft p {
  margin-bottom: 20px;
  font-family: "Roboto", Sans-serif;
  font-weight: 400;
  line-height: 28px;
  font-size: 1rem;
  color: #000;
}

.videoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (iframe video) */
}

.videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .videoSectionleft h1 {
    font-size: 35px;
  }
  .videoSectionleft p {
    margin-bottom: 10px;
    line-height: 25px;
  }
}
@media (max-width: 777px) {
  .videoSection {
    display: block;
  }
  .videoSectionleft h1 {
    font-size: 50px;
  }
  .videoSectionleft p {
    margin-bottom: 20px;
    line-height: 28px;
  }
}
@media (max-width: 428px) {
  .videoSectionleft h1 {
    font-size: 30px;
  }
  .videoSectionleft p {
    margin-bottom: 10px;
    line-height: 25px;
  }
}
/* /////////////////////////BOOK NOW SECTION CSS////////////////// */
.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto", Sans-serif;
  padding: 80px 0px 80px 0px;
  background-color: #eb0009;
}

.iconDiv {
  animation: slideFromLeft 1s ease-out; /* Animation for sliding from left */
}
.iconCar {
  font-size: 95px;
  color: #fff;
  font-family: "Roboto", Sans-serif;
}
.headingDiv h1 {
  line-height: 1;
  font-size: 55px;
  font-weight: 600;
  color: #fff;
}
.buttonDiv a {
  text-decoration: none;
}
#buttonDiv {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  color: #000000;
  border: none;
  background-color: rgb(239, 219, 31);
  font-family: "Roboto", Sans-serif;

  padding: 15px 45px 15px 45px;
  transition: all 0.3s ease;
  margin-top: 20px;
}
#buttonDiv:hover {
  background-color: lightgreen;

  transform: scale(1.1);
}

.headingDiv,
.buttonDiv {
  margin-top: 10px; /* Adjust margin as needed */
}

/* Keyframes for sliding animation */
@keyframes slideFromLeft {
  from {
    transform: translateX(-2000%);
  }
  to {
    transform: translateX(0);
  }
}
@media (max-width: 460px) {
  .headingDiv h1 {
    font-size: 30px;
  }
}
/* /////////////////////////why choose us css/////////// */
.features {
  padding: 2rem 0;
}
.features h4 {
  color: #eb0009;
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2em;
  text-align: center;
  font-family: "Roboto", Sans-serif;
  margin-bottom: 20px;
}
.features p {
  color: #7a7a7a;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
  font-family: "Roboto", Sans-serif;
}
.mainFeatures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  font-family: "Roboto", Sans-serif;
  text-align: center;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.column {
  padding: 25px 25px 55px 25px;
  background-color: #ffffff;

  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);

  transition: box-shadow 0.3s ease;
}

.column:hover {
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.203);
  border: 1px solid #eb0009;
}
.column span {
  font-size: 50px;
  color: #706c6c;
  display: inline-block;
  padding: 30px;
  margin-right: auto;
  margin-left: auto;
}
.column h3 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
  color: #000000;
}
.column p {
  font-size: 16px;
  font-family: "Roboto", Sans-serif;
  font-weight: 300;
  line-height: 1.7em;
  color: #7a7a7a;
}
#featuresIcon {
  transition: transform 0.3s ease;
}
#featuresIcon:hover {
  transform: scale(1.2);
  color: #eb0009;
}
@media (max-width: 1085px) {
  .features h4 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .features h4 {
    font-size: 30px;
  }
}
/* ///////////////////////////ENquiry Form ///////////////// */
.formContainer {
  padding: 100px 0;
  background-color: #eb0009;
}
.innerFormSection {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.enquiryForm {
  padding: 150px 150px 150px 150px;
  background-color: #fff;
}
.enquiryForm h4 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-size: 55px;
  font-weight: 600;
  padding-bottom: 50px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.formGroup label {
  margin-bottom: 0.5rem;
}
.formGroup input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.formGroup input:focus {
  border: 1px solid #eb0009;
}
.formGroup textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.formGroup textarea:focus {
  border: 1px solid #eb0009;
}
.formBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
}
#formBtn {
  padding: 15px 75px 15px 75px;

  background-color: #eb0009;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
#formBtn:hover {
  border: 1px solid red;
  background-color: #fff;
  color: red;
}

@media (max-width: 1024px) {
  .formContainer {
    padding: 100px 150px 100px 150px;
  }
  .innerFormSection {
    max-width: 1024px;
  }
  .enquiryForm {
    padding: 75px 75px 75px 75px;
    background-color: #fff;
  }
}
@media (max-width: 767px) {
  .formContainer {
    padding: 100px 75px 100px 75px;
  }
  .innerFormSection {
    max-width: 767px;
  }
  .enquiryForm h4 {
    font-size: 45px;
  }
  .enquiryForm {
    padding: 50px 50px 50px 50px;
    background-color: #fff;
  }
}
@media (max-width: 524px) {
  .formContainer {
    padding: 50px 30px 50px 30px;
  }
  .enquiryForm h4 {
    font-size: 30px;
  }
  .innerFormSection {
    max-width: 524px;
  }
  .enquiryForm {
    padding: 30px 30px 30px 30px;
    background-color: #fff;
  }
}
/* ///////////////////////////////////////Product cataelog css//////////////////////////// */
/* ////////////////////Product card */
  .SimulatedProductCatelog {
    text-align: center;
    background-color: #eb0009;
    padding: 4rem 0px;
  }
  .SimulatedProductCatelog h2 {
    font-size: 4rem;
    font-weight: 700;
    color: #fff;
  }
.chooseProductSection {
  max-width: 1600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.gridViewSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px; /* Gap between grid items */
  margin-top: 20px;
}
.gridViewSection ul {
  padding: 0px;
  margin: 0px;
}
.gridViewSection ul p {
  padding: 0px;
  margin: 0px;
}
.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px; /* Gap between grid items */
  margin-top: 20px;
}
.productCard {
  width: 100%; /* Take full width of each grid cell */
}
.ratingAndPrice {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}
.productCard img {
  width: 100%;
  height: auto;
}
.productCard {
  padding: 5px;
  border: 1px solid rgba(167, 166, 166, 0.737);
  border-radius: 4px;
  background-color: #fff;
}
.productDetails h3 {
  font-size: 15px !important;
  color: #fff;
  text-align: center;
  background-color: black;
  width: 100%;
  padding: 20px;
  margin: 10px 0px;
}
.cardDetails li {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  margin-bottom: 10px;
}
.rating {
  margin-bottom: 10px;
}

.rating span {
  color: #ed2025;
  font-size: 1.2rem;
  font-weight: 900;
}

.filled {
  color: #ed2025;
}
.postcodeList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 12px; /* Gap between grid items */
  padding-left: 0px;
}
.postcodeList li {
  padding: 9px;
  background-color: #eb0009;
  color: #fff;
  text-align: center;
  border: 1px solid #2d2d2d;
}

.price {
  font-size: 22px !important;
  color: #ed2025 !important;
  font-weight: 600 !important;
}
.cardDetails p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #111111;
}
.postCodeBlock p {
  font-size: 1.2rem;
  font-weight: 600;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bookNow,
.more {
  padding: 10px 20px;
  background-color: #111111;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bookNow:hover,
.more:hover {
  background-color: #eb0009;
}
/* /////////////// reviews section ///////////////// */
.facebookReviewsSection {
  text-align: center;
  padding: 2rem 0;
}

.facebookReviewsContainer {
  padding: 2rem 0;
  background-color: red;
}
.facebookReviewsContainer hr {
  border: 2px solid #fff;

  opacity: 1;
  text-align: center;
  width: 30%;
  margin: 0 auto;
}

#heading1 {
  color: white;
  font-family: "Arial", Sans-serif;
  font-size: 65px;
}

.reviewsList {
  display: grid;
  grid-gap: 20px;
}

.review {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.followUsLinks {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}
.followUsLinks hr {
  border: 1.05px solid #fff;
  text-align: center;
  width: 38%;
  margin: 0 auto;
}
.followUsLinks h4 {
  margin-top: 1.8rem;
  font-family: "Arial", Sans-serif;
  color: #ffffff;
  line-height: 1;
  font-size: 2.8em;
  font-weight: 600;
  margin-bottom: 1.8rem;
}
.socialFollowIcons {
  display: flex;
  justify-content: space-evenly;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: 2rem 0;
}

.socialFollowIcons a {
  display: inline-block;
  background-color: white;
  border-radius: 6px;
  padding: 8px 10px 8px 10px;

  transition: transform 0.3s;
}
#FollowIcons {
  color: #eb0009;
  font-size: 1.7rem;
}
.socialFollowIcons a:hover {
  transform: translateY(-5px);
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333; /* Change color as needed */
}
@media (max-width: 770px) {
  .facebookReviewsSection #heading1 {
    font-size: 45px;
  }
  #heading2 {
    font-size: 45px;
  }
  .followUsLinks h4 {
    font-size: 2.8em;
  }
}
