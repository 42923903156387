.AdiModuleOnecontainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 4rem;
}
.AdiModuleOneheader {
  margin-top: 1rem;
  position: relative;
  width: 100%;
  background-image: url(../../../../../assets/images/moduleonebanner.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 10px;
}
.AdiModuleOneheading {
  max-width: 1640px;
  width: 100%;
  padding: 100px 1rem;
  margin-right: auto;
  margin-left: auto;
  position: sticky;
}
.AdiModuleOneheading h1 {
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 4px #000000;
}
.AdiModuleOneTextArea {
  padding: 1rem;
  margin-top: 1rem;
  max-width: 1240px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.AdiModuleOneTextArea h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: rgb(224, 241, 255);
  font-weight: 400;
}
.AdiModuleOneTextBox {
  max-width: 1000px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: white;
}
.AdiModuleOneTextBox label {
  font-size: 1.2rem;
  padding: 0.5rem;
}
.AdiModuleOneTextBox textarea {
  border: 1px solid rgba(58, 65, 101, 0.794);
  background-color: #0a0a10;
  border-radius: 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  color: rgba(235, 238, 255, 0.871);
}
.AdiModuleOneTextBox textarea:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 108, 255, 0.417);

  border: 1px solid #86b7fe;
}
.AdiModuleOneTextBox button {
  border-radius: 6px;
  padding: 0.7rem 2rem;
  background-color: #0063f6;
  color: white;
  border: none;
  margin: 1rem 0.5rem;
}
.thoughtsListArea {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(58, 65, 101, 0.794);
  border-radius: 6px;
}
.thoughtsListArea ul {
  padding-left: 0px;
}
.thoughtsListArea ul li {
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgba(58, 65, 101, 0.794);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #005362;
  box-shadow: 0 8px 8px -4px rgb(1, 40, 53);
  margin-top: 0.5rem;
}
.thoughtsListArea ul li p {
  margin-bottom: 0px;
}
.thoughtsListArea ul li span {
  min-width: 80px;
}
#editListIcon {
  color: #00159a;
  font-size: 1.5rem;
  margin: 0px 0.5rem;
  cursor: pointer;
}
#editListIcon:hover {
  transform: scale(1.2);
  transition: ease-in-out 0.3s;
}
#binListIcon {
  color: #d50039;
  font-size: 1.5rem;
  margin: 0px 0.5rem;
  cursor: pointer;
}
#binListIcon:hover {
  transform: scale(1.2);
  transition: ease-in-out 0.3s;
}

@media (max-width: 767px) {
  .AdiModuleOneheading h1 {
    font-size: 2rem;
  }
  .AdiModuleOneTextArea h2 {
    font-size: 1.5rem;
  }
  .AdiModuleOneTextBox label {
    font-size: 1rem;
  }
  .thoughtsListArea ul li {
    flex-direction: column;
    align-items: flex-start;
  }
  .thoughtsListArea ul li span {
    min-width: 100%;
    width: 100%;
    text-align: end;
  }
}
@media (max-width: 467px) {
  .AdiModuleOneheading h1 {
    font-size: 1.5rem;
  }
  .AdiModuleOneTextArea h2 {
    font-size: 1.2rem;
  }
}

.AdiModuleContentBox {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 1rem;
}
.AdiModuleContentBox h2 {
  font-size: 2rem;
  margin: 1rem 0px;
  color: #e5efff;
  text-transform: uppercase;
}

.AdiModuleContentParaBox {
  padding: 1rem;
  border: 1px solid rgb(54, 61, 106);
  color: white;
  font-size: 1.1rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.AdiModuleContentParaBox p{
  margin-bottom: 0px;
}
.AdiModuleContentParaBox:hover {
  box-shadow: 0 0 0 0.25rem rgba(10, 108, 255, 0.417);
  border-radius: 6px;
  border: 1px solid #86b7fe;
}

.flipContainer {
  perspective: 1000px; /* Adds depth to the 3D effect */
  max-width: 1000px;
  width: 100%;
  height: 300px;

  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
}

.flipbox {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Ensures the child elements are positioned in 3D */
  transition: transform 0.6s ease-in-out;
}

.flipContainer:hover .flipbox {
  transform: rotateY(180deg); /* Flip the box when hovered */
}

/* Front of the box */
.flipboxfront,
.flipboxback {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hides the back side when flipped */
  display: flex;
  justify-content: center;

  font-family: Arial, sans-serif;
  text-align: center;
  cursor: pointer;
}

.flipboxfront {
  background: linear-gradient(135deg, #008f6d, #014c57);
  align-items: center;
}

.flipboxback {
  background-color: #0e0b18d7;
  transform: rotateY(180deg); /* Rotate the back side initially */
  padding: 20px;
  overflow-y: auto;
  border: 1px solid rgb(56, 51, 112);
}

.flipboxfront #fafapointing {
  font-size: 32px;
  font-weight: 700;

  color: #ffffff;
}

.flipboxback ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.flipboxback li {
  margin-bottom: 20px;
  text-align: left;
  color: white;
}

.flipboxback strong {
  color: #00b3b3;
  font-size: 18px;
}

@media (max-width: 600px) {
  .flipContainer {
    perspective: 120px;
    height: 400px;
  }
  .AdiModuleContentBox h2 {
    font-size: 1.7rem;
  }
  .flipboxfront #fafapointing {
    font-size: 25px;
    font-weight: 600;
  }
}
@media (max-width: 430px) {
  .AdiModuleContentBox h2 {
    font-size: 1.5rem;
  }
  .flipboxfront #fafapointing {
    font-size: 20px;
    font-weight: 600;
  }
}

.instructorContainer {
  padding: 2rem 0;
}

.innerInstructorContainer {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
.innerInstructorContainer h2 {
  font-size: 2rem;
  text-align: center;
  color: #00ffc4;
}
.innerInstructorContainer h4 {
  color: white;
  text-align: center;
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2em;

  margin-bottom: 20px;
}

.detailsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;

  margin-top: 3rem;
}

.trainingDetails {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 20px #545353;
}
.trainingDetails h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.trainingDetails hr {
  width: 12%; /* Set width to 12% */
  opacity: 1;
  margin: 0 auto;
  /* Change color as needed */
}
.trainingDetails p {
  padding: 15px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.instructorfooter p {
  color: white;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
}
.instructorfooter strong a {
  color: #eb0009;
  text-decoration: none;
  line-height: 28px;
  font-weight: bold;

  margin-left: 4px;
}
@media (max-width: 894px) {
  .innerInstructorContainer h4 {
    font-size: 45px;
  }
}
@media (max-width: 620px) {
  .innerInstructorContainer h4 {
    font-size: 30px;
  }
  .innerInstructorContainer h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 415px) {
  .innerInstructorContainer h4 {
    font-size: 23px;
  }
}

/* ///////////////////////////////////////////// */
.flipContainer2 {
  perspective: 1000px; /* Adds depth to the 3D effect */
  max-width: 1000px;
  width: 100%;
  height: 400px;

  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
}

.flipbox2 {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d; /* Ensures the child elements are positioned in 3D */
  transition: transform 0.6s ease-in-out;
}

.flipContainer2:hover .flipbox2 {
  transform: rotateY(180deg); /* Flip the box when hovered */
}

/* Front of the box */
.flipboxfront2,
.trainingDetails {
  position: absolute;

  backface-visibility: hidden; /* Hides the back side when flipped */

  text-align: center;
  cursor: pointer;
}

.flipboxfront2 {
  background-color: #032624d7;
  align-items: flex-start;
  display: flex;
  justify-content: center;

  border-radius: 6px;
  height: 100%;
}
.flipboxfront2 h2 {
  position: sticky;
  font-weight: 600;
  color: white;
  margin-top: 2rem;
}
#flipboxfront23 {
  background-image: url(../../../../../assets/images/moduleCrausalImg-1.jpg);
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 400px;
}
#flipboxfront24 {
  background-image: url(../../../../../assets/images/moduleCrausalImg-2.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
}
#flipboxfront25 {
  background-image: url(../../../../../assets/images/moduleCrausalImg-3.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
}
#flipboxfront26 {
  background-image: url(../../../../../assets/images/moduleCrausalImg-4.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
}
#flipboxfront27 {
  background-image: url(../../../../../assets/images/moduleCrausalImg-5.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
}
#flipboxfront28 {
  background-image: url(../../../../../assets/images/moduleCrausalImg-6.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 400px;
}

.trainingDetails {
  background-color: #0e0b18d7;
  transform: rotateY(180deg); /* Rotate the back side initially */
  padding: 20px;
  overflow-y: auto;
  border: 1px solid rgb(56, 51, 112);
  width: 100%;
  height: 100%;
}

.flipboxfront2 #fafapointing2 {
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
}

.trainingDetails ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#trainingDetails1 {
  background: linear-gradient(135deg, #00ffe1, #2600fd);
}
#trainingDetails2 {
  background: linear-gradient(135deg, #a90303, #ff8585);
}
#trainingDetails3 {
  background: linear-gradient(135deg, #380042, #da14fd);
}
#trainingDetails4 {
  background: linear-gradient(135deg, #00ffc4, #004037);
}
#trainingDetails5 {
  background: linear-gradient(135deg, #ffac47, #fa4700);
}
#trainingDetails6 {
  background: linear-gradient(135deg, #ff33a4, #62002e);
}
#trainingDetails1 h3 {
  color: #08016d;
}
#trainingDetails2 h3 {
  color: #720000;
}
#trainingDetails3 h3 {
  color: #cb2c92;
}
#trainingDetails4 h3 {
  color: #003535;
}
#trainingDetails5 h3 {
  color: #b3040a;
}
#trainingDetails6 h3 {
  color: #740030;
}
#trainingDetails1 hr {
  border: 1.6px solid #08016d;
}
#trainingDetails2 hr {
  border: 1.6px solid #720000;
}
#trainingDetails3 hr {
  border: 1.6px solid #cb2c92;
}
#trainingDetails4 hr {
  border: 1.6px solid #003535;
}
#trainingDetails5 hr {
  border: 1.6px solid #b3040a;
}
#trainingDetails6 hr {
  border: 1.6px solid #740030;
}

.trainingDetails li {
  margin-bottom: 20px;
  text-align: left;
  color: white;
}

.trainingDetails strong {
  color: #00b3b3;
  font-size: 18px;
}

@media (max-width: 600px) {
  .flipContainer2 {
    perspective: 120px;
    height: 400px;
  }
}

/* ////////////////////////////////// */
.quizStartDiv {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
}

.startQuizSection {
  box-shadow: 0 0 10px #ccc;
  text-align: center;
  padding: 4rem;
  font-family: "Roboto", sans-serif;
}
.startQuizSection h2 {
  font-size: 1.9rem;
  font-weight: 300;
  margin: 2rem 0px;
}
.startQuizSection h3 {
  font-size: 1.9rem;
  font-weight: 300;
  margin: 1.5rem 0px;
}
.startQuizSection p {
  margin: 1rem 0px;
  font-size: 16px;
  font-weight: 300;
}
.startQuizSection button {
  margin: 2rem 0px;
  padding: 12px 40px;
  border: none;
  background: linear-gradient(135deg, #00ffc4, #004037);
  color: #fff;
  box-shadow: 0 0 10px #ccc;
  transition: ease-in-out 0.3s;
}
.startQuizSection button:hover {
  transition: ease-in-out 0.3s;
  background: linear-gradient(135deg, #004037, #00ffc4);
}
/* PowerWord.css */
.powerword {
  display: flex;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  cursor: pointer;
  position: relative;
  flex-direction: column;
}

.powerwordletter {
  margin: 0 10px;
  position: relative;
  opacity: 1;
  transition: transform 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.powerwordletter  p{
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}
.powerwordletter:hover {
  transform: scale(1.2);
  color: #007bff; /* Change color when hovered */
}
#powerwordPara{
  font-size: 14px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  text-align: center;
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: rgb(196, 196, 196);
}

.powerwordword {
  position: absolute;
  top: 50px; /* Adjust this based on where you want the word to appear */
  left: 0;
  font-size: 2rem;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  transition-delay: 0.2s;
}

.powerword:hover .powerwordword {
  visibility: visible;
  opacity: 1;
}

.powerwordword {
  opacity: 0;
  visibility: hidden;
}

.powerwordword {
  transition: visibility 0s linear 0.3s, opacity 0.3s ease-in-out;
}

.powerwordword {
  font-size: 1.5rem;
  font-weight: normal;
  color: #555;
}
/* /////////////////////////////////////////////// */
.detailsContainer1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;

  margin-top: 3rem;
}

.trainingDetails1 {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 20px #545353;
}
.trainingDetails1 h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.trainingDetails1:nth-child(1) {
  background: linear-gradient(135deg, #00ffe1, #2600fd);
}
.trainingDetails1:nth-child(2) {
  background: linear-gradient(135deg, #a90303, #ff8585);
}
.trainingDetails1:nth-child(3) {
  background: linear-gradient(135deg, #380042, #da14fd);
}
.trainingDetails1:nth-child(4) {
  background: linear-gradient(135deg, #00ffc4, #004037);
}
.trainingDetails1:nth-child(5) {
  background: linear-gradient(135deg, #ffac47, #fa4700);
}
.trainingDetails1:nth-child(6) {
  background: linear-gradient(135deg, #ff33a4, #62002e);
}
.trainingDetails1:nth-child(1) h3 {
  color: #08016d;
}
.trainingDetails1:nth-child(2) h3 {
  color: #720000;
}
.trainingDetails1:nth-child(3) h3 {
  color: #cb2c92;
}
.trainingDetails1:nth-child(4) h3 {
  color: #003535;
}
.trainingDetails1:nth-child(5) h3 {
  color: #b3040a;
}
.trainingDetails1:nth-child(6) h3 {
  color: #740030;
}
.trainingDetails1:nth-child(1) hr {
  border: 1.6px solid #08016d;
}
.trainingDetails1:nth-child(2) hr {
  border: 1.6px solid #720000;
}
.trainingDetails1:nth-child(3) hr {
  border: 1.6px solid #cb2c92;
}
.trainingDetails1:nth-child(4) hr {
  border: 1.6px solid #003535;
}
.trainingDetails1:nth-child(5) hr {
  border: 1.6px solid #b3040a;
}
.trainingDetails1:nth-child(6) hr {
  border: 1.6px solid #740030;
}
.trainingDetails1 hr {
  width: 12%; /* Set width to 12% */
  opacity: 1;
  margin: 0 auto;
  /* Change color as needed */
}
.trainingDetails1 p {
  padding: 15px;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.AdiModuleContentBoxLists{
  padding: 1rem;
}
.AdiModuleContentBoxLists ul{
padding-left: 0px;
max-width: 800px;
width: 100%;
margin-left: auto;
margin-right: auto;
}
.AdiModuleContentBoxLists ul li{
  padding: 1rem;
  background: linear-gradient(135deg, #03bad2, #004037);
  color: rgb(27, 27, 27);
  margin: 0.5rem;
  
  font-size: 1.1rem;
  
  }
  .AdiModuleContentBoxLists ul li strong{
    color: #003535;
  }