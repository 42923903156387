@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer a {
  text-decoration: none;
}

.Footer {
  background: linear-gradient(135deg, #0d0065, #00032a);
  padding: 4rem 0 0;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  color: white;
  
  border-top-left-radius: 13em 3em;  /* Horizontal and vertical radii for top-left corner */
  border-top-right-radius: 13em 3em; 
}

.container {
  max-width: 1540px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
}

.footerContent h3 {
  display: block;

  font-size: 18px;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

.col1 {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 33.333333%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col1 a img {
  width: 300px;
  margin-bottom: 20px;
}

.footerContent p {
  margin-bottom: 10px;
  line-height: 28px;
  font-family: "Montserrat", sans-serif;

  font-size: 1rem;
  font-weight: 400;
}

.col2 {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 33.333333%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col2 a {
  text-decoration: none;
}
article h4 {
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
}

/* article a h4:hover {
  color: #ed2025;
} */

.col3 {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  max-width: 33.33333%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.hoursLists table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.hoursLists table th {
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  line-height: 1.2;
  font-family: "Montserrat", sans-serif;
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  padding: 5px;
  border-top: 1px solid #dee2e6;
}

.hoursLists table td {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6;
  padding: 5px;
  font-family: "Montserrat", sans-serif;
}

#arrowIcon {
  color: #ed2025;
}

.lowerFooter a {
  text-decoration: none;
  color: #dee2e6;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.innerSection {
  display: flex;
  justify-content: space-evenly;
}

.lowerCol1 {
  max-width: 33.333333%;

  display: block;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  text-align: center;
  padding-left: 15px;
}

.lowerCol-2 {
  max-width: 33.333333%;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.lowerCol3 {
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
}

.lowerFooter h3 {
  display: block;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}
.lowerCol2{
  padding: 2rem 0px;
}
.quicklinks ul {
  padding-left: 0;
}

#quicklinks li {
  display: inline-block;
  width: 48%;
  margin: 0 0 10px;
}

#quicklinks li a {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-decoration: none;
}

#quicklinks li a:hover {
  color: #ed2025;
}

#arrowIcon a {
  font-style: normal;

  font-size: 1.3rem;
  font-weight: bold;
  padding-top: 5px;
}

.footerImg {
  display: flex;
  padding-left: 0;
  justify-content: center;
}

.footerImg li {
  margin: 2px;
}

#sslLogo {
  width: 300px;
  margin: 10px 0;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

.copyRight {
 
  font-family: "Montserrat", sans-serif;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 7px 10px;
}

.copyRight p {
  margin-bottom: 0;
  line-height: 28px;
  display: block;
}
.copyRight a{
  color: #ff5c98;
  text-decoration: none;
  font-weight: 500;
}
.copyRight a:hover{
  color: #ffcefb;
}
/* ///////////////////////////////////// */
.followUsLinks {
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 1rem;
}

.socialFollowIcons {
  display: flex;

  gap: 50px;
  padding: 2rem 0;
}

.socialFollowIcons a {
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  padding: 8px 10px 8px 10px;
  color: rgb(0, 0, 0);
  transition: transform 0.3s;
}

#FollowIcons {
  font-size: 1.7rem;
}

.socialFollowIcons a:hover {
  transform: translateY(-5px);
  background-color: rgb(0, 0, 0);
  color: white;
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333;
}

@media (max-width: 950px) {
  .footerContent {
    display: block;
  }

  .col1 {
    max-width: 100%;
    width: 100%;
  }

  .col2 {
    max-width: 100%;
    width: 100%;
  }

  .col3 {
    max-width: 100%;
    width: 100%;
  }

  .lowerCol2 {
    margin-top: 1rem;
  }
}

@media (max-width: 770px) {
  .innerSection {
    display: block;
  }

  .lowerCol1 {
    max-width: 100%;
    width: 100%;
  }

  .lowerCol2 {
    max-width: 100%;
    width: 100%;
  }

  .lowerCol3 {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 720px) {
  .socialFollowIcons {
    justify-content: center;
  }
}
@media (max-width: 460px) {
  .socialFollowIcons {
    gap: 20px;
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .socialFollowIcons {
    
    justify-content: center;
  }
  .socialFollowIcons a {
   
    padding: 5px 10px 5px 10px;
   
  }
  #FollowIcons {
    font-size: 1.2rem;
  }
}

@media (max-width: 350px) {
  .col1 a img {
    width: 220px;
  }
}
