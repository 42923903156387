* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.weProudlySupport {
  padding-top: 1rem;
  font-family: "Antonio", sans-serif;
  background-color: #000000;
  padding-bottom: 4rem;
}
.imageSection {
  text-align: center;
  position: sticky;
}
.imageSection img {
  max-width: 300px;
  width: 100%;
}
.csrPageFront{
  background: url("../../assets/images/partnerbanner2.jpg");
  color: rgb(255, 255, 255);
  background-size: cover;
  border-radius: 10px;  
  padding: 14rem 1rem;
  position: relative;
}
.imageSection h2 {
  font-size: 4rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: white;
  text-align: center;
}
/* /////////////////////////whoDoWeSupport/////////// */
.whoDoWeSupport {
  padding: 2rem 0px;
}

.whoDoWeSupport h2 {
  text-align: center;
  color: #ff0000;
  font-size: 3rem;
  font-weight: 700;
}

.features {
  padding: 2rem 0;
}
.features h4 {
  color: #FFF;
  font-size: 65px;
  font-weight: 700;
  line-height: 1.2em;
  text-align: center;

  margin-bottom: 20px;
}
.features p {
  color: #FFF;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
}
.mainFeatures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  text-align: center;
  max-width: 1400px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.column {
  padding: 25px 25px 55px 25px;
 
  background: linear-gradient(135deg, #69007199, #76012c76);
  border-radius: 10px;
  box-shadow: 0px 0px 55px 5px rgba(255, 255, 255, 0.06);

  transition: box-shadow 0.3s ease;
}

.column:hover {
  box-shadow: 0px 0px 55px 5px rgba(255, 255, 255, 0.203);
  border: 1px solid #eb0009;
}
.column img {
  aspect-ratio: 1/1;
  object-fit: contain;
  max-width: 200px;
  width: 100%;
}
.column p {
  font-size: 16px;
  margin-top: 1rem;

  font-weight: 300;
  line-height: 1.7em;
  color: #FFF;
}
@media (max-width: 600px) {
  .mainFeatures {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .imageSection h2{
    font-size: 3.4rem;
  }
}
@media (max-width: 600px) {
  .mainFeatures {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .imageSection h2{
    font-size: 2.5rem;
  }
}