

:root {
    --black: hsl(0, 0%, 10%);
    --white: hsl(0, 0%, 95%);
    --dark-white: hsl(0, 0%, 65%);
    --pink: hsl(329, 83%, 61%);
    --purple: hsl(274, 83%, 61%);
    --gray: hsl(227, 14%, 38%);
    --light-gray: hsl(218, 17%, 88%);
  
    --p: 2rem;
    --border-r: 1rem;
  
    --fs-sm: clamp(0.8rem, (1vw + 0.5rem), 1rem);
    --fs-regular: clamp(1rem, (1vw + 0.5rem), 1.25rem);
    --fs-lg: clamp(1.5rem, (1vw + 2rem), 3rem);
  } 
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  ul {
    list-style: none;
    padding: 0px;
  }
  .card-top p{
   
    margin-bottom: 0px;
  }
 
  .card-bottom__list{
    padding-left: 0px;
  }
  .subscription-cardBox{
    background-color: black;
  }
  .cardBody{
    min-height: 100vh;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
  width: 100%;
  padding: 2rem 1rem 10rem 1rem;
  display: grid;
  font-family: "Antonio", sans-serif;
  gap: 30px;
  place-items: center;
 
  }
  #SubsHeading{
    text-align: center;
    font-size: 5rem;
    background: linear-gradient(45deg, var(--pink), var(--purple) 60%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  #SubDesc{
    text-align: center;
    font-size: 2rem;
    background: linear-gradient(45deg, var(--pink), var(--purple) 60%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  @media (max-width: 767px){
    #SubsHeading{
        font-size: 4rem;
    }
    #SubDesc{
      font-size: 1.5rem;
    }
  }
  @media (max-width: 467px){
    #SubsHeading{
        font-size: 3rem;
    }
    #SubDesc{
      font-size: 1rem;
    }
  }
  .card {
      font-family: "Antonio", sans-serif;
    min-height: 300px;
    max-width: 800px;
    width: 100%;
    padding: var(--p);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    row-gap: var(--p);
    border-radius: var(--border-r);
  
    & .card-top {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: calc(var(--p) * 0.75);
  
      & .card-top__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: calc(var(--p) * 0.25);
  
        @media screen and (width > 420px) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          column-gap: calc(var(--p) * 0.25);
        }
      }
  
      & .card-top__info-icon {
        display: block;
        height: 100%;
      }
      & .card-top__info-header {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        line-height: 1.25;
  
        & h1 {
          font-size: var(--fs-regular);
          font-weight: 600;
        }
        & p {
          font-size: var(--fs-sm);
          color: var(--gray);
        }
      }
  
      & .card-top__price {
        display: flex;
        align-items: first baseline;
        line-height: 1;
  
        & h2 {
          font-size: 2rem;
          background: linear-gradient(45deg, var(--pink), var(--purple) 60%);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }
  
        & p {
          font-size: var(--fs-regular);
        }
      }
  
      & .card-top__info-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: calc(var(--p) * 0.125);
        background-color: var(--light-gray);
        padding-block: calc(var(--p) * 0.25);
        padding-inline: calc(var(--p) * 0.75);
        border-radius: calc(var(--border-r) * 0.5);
      }
    }
  
    & .card-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: calc(var(--p) * 0.75);
      font-size: var(--fs-sm);
  
      & .card-bottom__btn {
        width: 100%;
        display: grid;
        place-items: center;
        background-color: var(--black);
        color: var(--white);
        padding-block: calc(var(--p) * 0.5);
        border-radius: calc(var(--border-r) * 0.5);
        font-size: var(--fs-regular);
        cursor: pointer;
      }
  
      & .card-bottom__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: calc(var(--p) * 0.5);
  
        & .card-bottom__list-item {
          width: 100%;
          display: flex;
          align-items: center;
          column-gap: calc(var(--p) * 0.25);
        }
      }
    }
  }

  .coupon-section {
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    gap: 10px;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    justify-content: center;
  }
  
  .coupon-input {
    padding: 10px;
    max-width: 300px;
    width: 100%;
  
  }
  
  .coupon-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  max-width: 150px;
  width: 100%;

  }
  
  .coupon-message {
    color: green;
    text-align: center;
    margin-top: 10px;
  }
  
  .coupon-button:hover {
    background-color: #0056b3;
  }
  @media (max-width: 477px) {
    .coupon-section{
     flex-direction: column;
     align-items: center;
    }
    .coupon-button{
      max-width: 300px;
      width: 100%;

    }
  }