@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slide,
.slide .column {
  max-width: 400px;
  width: 100%;
}
.slide {
  transition: 0.5s;
  transform: scale(0.7);
  filter: blur(5px);
}
.slide-active {
  filter: blur(0px);
  opacity: 1;
  transform: scale(1.1);
}

.mainFeatures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  text-align: center;
  max-width: 1500px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.column {
  padding: 25px 25px 55px 25px;
  border-radius: 6em/2em;
  background-color: #ffffff;
  place-items: center;
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);

  transition: box-shadow 0.3s ease;
}
.column a {
  text-decoration: none;
  color: #ff0004;
}

.column:hover {
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.203);
  border: 1px solid #ff0004;
}
.column span {
  font-size: 50px;
  color: #706c6c;
  display: inline-block;
  padding: 30px;
  margin-right: auto;
  margin-left: auto;
}
.column h3 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ff0004;
}
.column p {
  font-size: 16px;

  font-weight: 300;
  line-height: 1.7em;
  color: #7a7a7a;
}
.column span {
  background-color: #ff0004;
  padding: 30px;
  border-radius: 50%;
  display: inline-block;
  font-size: 50px;
  color: #fff;
}
#featuresIcon {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
}
.column span:hover {
  transform: scale(1.2);
  background-color: #fff;
  transition: 0.3s ease-in-out;
  color: #ff0004;
}
#featuresIconSRU {
  font-size: 6rem;
}
@media (max-width: 1085px) {
  .features h4 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .features h4 {
    font-size: 30px;
  }
  .mainFeatures {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
