* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.drivingInstructorTraining {
  background-color: #00041ced;
  padding-bottom: 5rem;
  font-family: "Antonio", sans-serif;
}
.dITPageseees {
  background: url("../../assets/images/drivingInstructorBanner.jpg");
  position: relative;
  background-size: cover;
  background-position: bottom;
  padding: 10rem 0px;
}

.dItFrontHeading h2 {
  position: sticky;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 4.5rem;
  color: white;
  text-align: center;
}
@media (max-width: 770px) {
  .dItFrontHeading h2 {
    font-size: 3.7rem;
  }
}
@media (max-width: 640px) {
  .dItFrontHeading h2 {
    font-size: 3rem;
  }
}
@media (max-width: 540px) {
  .dItFrontHeading h2 {
    font-size: 2.5rem;
  }
}

/* ////////////////////////////////
 */

.drivingLessonsCd {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 1rem 0px;
}
.drivingLessonsCd section a {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  background-color: rgb(184, 6, 6);
  padding: 5px 1rem;
  text-decoration: none;
}
.drivingLessonsCd section p {
  margin: 0px;
  color: white;
  font-size: 1.5rem;
  font-family: "Antonio", sans-serif;
}
#IconsS {
  font-size: 1.8rem;

  color: white;
}
/* /////////////////////////why choose us css/////////// */
.features {
  padding: 2rem 0;
}
.features h4 {
  color: #b3040a;
  font-size: 3.5rem;
  font-weight: 900;
  line-height: 1.2em;
  text-align: center;

  margin-bottom: 20px;
}
.features p {
  color: #a1a1a1;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
}
.mainFeatures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;

  text-align: center;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
}
.column {
  padding: 15px 15px 15px 15px;
  background-color: black;
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);

  transition: box-shadow 0.3s ease;
  opacity: 0;
  transform: translateY(100%);
  animation: slideUp 7s forwards;
}
.column:nth-child(1) h3 {
  color: red;
}
.column:nth-child(2) h3 {
  color: #fbc809;
}
.column:nth-child(3) h3 {
  color: #cb2c92;
}
.column:nth-child(4) h3 {
  color: #008081;
}
.column:nth-child(1) p {
  color: red;
}
.column:nth-child(2) p {
  color: #fbc809;
}
.column:nth-child(3) p {
  color: #cb2c92;
}
.column:nth-child(4) p {
  color: #008081;
}
.column:nth-child(1) {
  animation-delay: 0s;
}

.column:nth-child(2) {
  animation-delay: 1s;
}

.column:nth-child(3) {
  animation-delay: 2s;
}
.column:nth-child(4) {
  animation-delay: 3s;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.column:hover {
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.203);
  border: 1px solid #eb0009;
}
.column span {
  font-size: 50px;
  color: white;
  display: inline-block;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}
.column img {
  max-width: 120px;
  width: 100%;
}
.column h3 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
  color: white;
}
.column p {
  font-size: 16px;

  font-weight: 300;
  line-height: 1.7em;
  color: white;
}
#featuresIcon {
  transition: transform 0.3s ease;
}
#featuresIcon:hover {
  transform: scale(1.2);
  color: #eb0009;
}
@media (max-width: 1085px) {
  .features h4 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .features h4 {
    font-size: 30px;
  }
}
/* /////////////////////////BOOK NOW SECTION CSS////////////////// */
/* .sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    padding: 80px 0px 80px 0px;
    background-color: #eb0009;
  }
  
  .iconDiv {
    animation: slideFromLeft 1s ease-out;
 } /* Animation for sliding from left */
/*   
  .iconCar {
    font-size: 95px;
    color: #fff;
    
  }
  .headingDiv h1{
    line-height: 1;
    font-size: 55px;
    font-weight: 600;
    color: #fff;
  }
  .buttonDiv a {
    text-decoration: none;
  }
  #buttonDiv {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
   
    color: #000000;
    border: none;
    background-color: rgb(239, 219, 31);
    
   
    padding: 15px 45px 15px 45px;
    transition: all 0.3s ease;
    margin-top: 20px;
  }
  #buttonDiv:hover {
    background-color: lightgreen;
  
    transform: scale(1.1);
  }
 
  
  .headingDiv,
  .buttonDiv
  {
    margin-top: 10px; }/* Adjust margin as needed */

/* Keyframes for sliding animation */
/* @keyframes slideFromLeft {
    from {
      transform: translateX(-2000%);
    }
    to {
      transform: translateX(0);
    }
  }
  @media (max-width: 460px){
    .headingDiv h1{
        font-size: 30px;
    }
  }   */
/*////////////////How to become a Driving Instructor//////////////// */
.instructorContainer {
  padding: 2rem 0;
}

.innerInstructorContainer {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
}
.innerInstructorContainer h4 {
  color: white;
  text-align: center;
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2em;

  margin-bottom: 20px;
}
/* .innerInstructorContainer p{
    color: ;
    line-height: 28px;
    text-align: center;
    font-weight: 400;
    
    margin-bottom: 20px;
  } */
.detailsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;

  margin-top: 3rem;
}

.trainingDetails {
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 20px #545353;
}
.trainingDetails h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.trainingDetails:nth-child(1) {
  background: linear-gradient(135deg, #00ffe1, #2600fd);
}
.trainingDetails:nth-child(2) {
  background: linear-gradient(135deg, #a90303, #ff8585);
}
.trainingDetails:nth-child(3) {
  background: linear-gradient(135deg, #380042, #da14fd);
}
.trainingDetails:nth-child(4) {
  background: linear-gradient(135deg, #00ffc4, #004037);
}
.trainingDetails:nth-child(5) {
  background: linear-gradient(135deg, #ffac47, #fa4700);
}
.trainingDetails:nth-child(6) {
  background: linear-gradient(135deg, #ff33a4, #62002e);
}
.trainingDetails:nth-child(1) h3 {
  color: #08016d;
}
.trainingDetails:nth-child(2) h3 {
  color: #720000;
}
.trainingDetails:nth-child(3) h3 {
  color: #cb2c92;
}
.trainingDetails:nth-child(4) h3 {
  color: #003535;
}
.trainingDetails:nth-child(5) h3 {
  color: #b3040a;
}
.trainingDetails:nth-child(6) h3 {
  color: #740030;
}
.trainingDetails:nth-child(1) hr {
  border: 1.6px solid #08016d;
}
.trainingDetails:nth-child(2) hr {
  border: 1.6px solid #720000;
}
.trainingDetails:nth-child(3) hr {
  border: 1.6px solid #cb2c92;
}
.trainingDetails:nth-child(4) hr {
  border: 1.6px solid #003535;
}
.trainingDetails:nth-child(5) hr {
  border: 1.6px solid #b3040a;
}
.trainingDetails:nth-child(6) hr {
  border: 1.6px solid #740030;
}
.trainingDetails hr {
  width: 12%; /* Set width to 12% */
  opacity: 1;
  margin: 0 auto;
  /* Change color as needed */
}
.trainingDetails p {
  padding: 15px;
  text-align: justify;
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.instructorfooter p {
  color: white;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
}
.instructorfooter strong a {
  color: #eb0009;
  text-decoration: none;
  line-height: 28px;
  font-weight: bold;

  margin-left: 4px;
}
@media (max-width: 894px) {
  .innerInstructorContainer h4 {
    font-size: 45px;
  }
}
@media (max-width: 620px) {
  .innerInstructorContainer h4 {
    font-size: 30px;
  }
}
@media (max-width: 415px) {
  .innerInstructorContainer h4 {
    font-size: 23px;
  }
}
/* //////////////////////////////Info Text Container //////////////////////////// */
.infoTextContainer {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
}
.infoTextHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.infoTextHeading h1 {
  color: white;
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 1.5rem;
}
.infoTextHeading h1 span {
  color: #b3040a;
}
.infoTextHeading img {
  max-width: 150px;
  width: 100%;
}
.infoTextHeading2 h3 {
  color: #eb0009;
  font-size: 1.9rem;
  line-height: 1;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 847px) {
  .infoTextHeading h1 {
    font-size: 45px;
  }
}

@media (max-width: 587px) {
  .infoTextHeading h1 {
    font-size: 30px;
  }
}
@media (max-width: 467px) {
  .infoTextHeading h1 {
    font-size: 22px;
  }
}

/* //////////////reveiw section ///////////////////// */
/* /////////////// reviews section ///////////////// */
.facebookReviewsSection {
  text-align: center;
  padding: 2rem 0;
}

.facebookReviewsContainer {
  padding: 2rem 0;
  background-color: red;
}
.facebookReviewsContainer hr {
  border: 2px solid #fff;

  opacity: 1;
  text-align: center;
  width: 30%;
  margin: 0 auto;
}

#heading1 {
  color: white;
  font-family: "Arial", Sans-serif;
  font-size: 65px;
}

.reviewsList {
  display: grid;
  grid-gap: 20px;
}

.review {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.followUsLinks {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}
.followUsLinks hr {
  border: 1.05px solid #fff;
  text-align: center;
  width: 38%;
  margin: 0 auto;
}
.followUsLinks h4 {
  margin-top: 1.8rem;
  font-family: "Arial", Sans-serif;
  color: #ffffff;
  line-height: 1;
  font-size: 2.8em;
  font-weight: 600;
  margin-bottom: 1.8rem;
}
.socialFollowIcons {
  display: flex;
  justify-content: space-evenly;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: 2rem 0;
}

.socialFollowIcons a {
  display: inline-block;
  background-color: white;
  border-radius: 6px;
  padding: 8px 10px 8px 10px;

  transition: transform 0.3s;
}
#FollowIcons {
  color: #eb0009;
  font-size: 1.7rem;
}
.socialFollowIcons a:hover {
  transform: translateY(-5px);
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333; /* Change color as needed */
}
@media (max-width: 770px) {
  .facebookReviewsSection #heading1 {
    font-size: 45px;
  }
  #heading2 {
    font-size: 45px;
  }
  .followUsLinks h4 {
    font-size: 2.8em;
  }
}
/* /////////////////////testimonials ///////////////// */
.testimonialsContainer {
  text-align: center;
  padding: 70px 0;
  font-family: "Antonio", sans-serif;
}
.tmcontainer {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}
.tmcontainer h4 {
  color: white;
  font-size: 65px;
  font-weight: 600;
  text-align: center;
}
.testimonialContent {
  background-color: #eb0009;
  padding: 2rem;
}
#comma1 {
  text-align: left;
  font-size: 3rem;
  color: white;
}
#comma2 {
  text-align: right;
  font-size: 3rem;
  color: white;
}
.testimonialName {
  text-align: center;
  color: white;
  font-size: 1.75rem;
  font-weight: 700;
}
.testimonialComment {
  text-align: center;
  font-size: 1.3em;
  color: white;
  line-height: 28px;
  text-align: center;
}
.testimonialImage {
  text-align: center;
  height: auto;
  max-width: 100%;
  border-radius: 50%;
}
@media (max-width: 1200px) {
  .testimonialsContainer {
    padding: 100px 30px 100px 30px;
  }
}
@media (max-width: 500px) {
  .tmcontainer h4 {
    font-size: 45px;
  }
  .testimonialComment {
    font-size: 16px;
  }
}
/* ///////////////////////partners section /////////////////// */
/* ///////////////////////partners section /////////////////// */
.ourPartnersSection {
  background-image: url("../../assets/images/ourpartners.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.bgOverlay {
  background-color: black;
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
}
.ourPartnersSection h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 800;
  text-align: center;

  position: sticky;
}
.partnerSection {
  padding: 2rem 1rem;
  margin-top: 3rem;
  background-color: #ffffffe7;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  place-items: center;
}

.partnerSection img {
  width: 150px;
  aspect-ratio: 1/1;
  margin: 0px 1rem;
  object-fit: contain;

  position: sticky;
}

@media (max-width: 934px) {
  .partnerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
@media (max-width: 780px) {
  .partnerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 428px) {
  .ourPartnersSection h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
  }
  .partnerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
