.manualBanner {
  background: url("./intensivebannerImg.jpg");
  background-size: cover;
  position: relative;
  background-position: center;
  padding: 4rem 0px;
  border-radius: 20px;
}

.bannerImg {
  max-width: 48%;
  width: 100%;
  height: 400px;
  position: relative;
}
.bannerImg img {
  max-width: 150px;
  height: 150px;
  box-shadow: 0 0 20px rgba(206, 235, 255, 0.852);
  width: 100%;
  border-radius: 50%;
}

#homeDrivingImg1 {
  position: absolute;
  top: 10px;
  right: 10px;
}
#homeDrivingImg2 {
  position: absolute;
  top: -50px;
  right: 200px;
}

@keyframes smoothUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Move the images up */
  }
  100% {
    transform: translateY(0); /* Move the images back to original position */
  }
}
#homeDrivingImg1:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 255, 255);
  cursor: pointer;
}
#homeDrivingImg2:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 255, 255);
  cursor: pointer;
}
#homeDrivingImg3:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 255, 255);
  cursor: pointer;
}

#homeDrivingImg1,
#homeDrivingImg2 {
  animation: smoothUpDown 2s ease-in-out infinite; /* Animation applied to both images */
  /* Optional, to add some space between the images */
}

/* If you want to delay the second image */
#homeDrivingImg2 {
  animation-delay: 1s; /* Delay the second image's animation for staggered effect */
}

@media (max-width: 2100px) {
  .home-content-D h1 {
    font-size: 5.5rem;
  }
}
@media (max-width: 1900px) {
  .home-content-D h1 {
    font-size: 4.7rem;
  }
}
@media (max-width: 1600px) {
  .home-content-D h1 {
    font-size: 4.3rem;
  }
}
@media (max-width: 1400px) {
  .home-content-D h1 {
    font-size: 4rem;
  }
}

@media (max-width: 900px) {
  .home-content-D h1 {
    font-size: 4rem;
  }
  .home-content-D {
    max-width: 70%;
    width: 100%;
  }
  .banner-img {
    max-width: 30%;
    width: 100%;
  }
}
@media (max-width: 840px) {
  .home-content-D {
    max-width: 80%;
    width: 100%;
  }
  .bannerImg {
    max-width: 20%;
    width: 100%;
  }
}
@media (max-width: 777px) {
  .home-banner-flex {
    flex-direction: column-reverse;
  }
  .home-content-D {
    max-width: 100%;
  }
  .bannerImg {
    max-width: 100%;
    height: 50px;
  }
  .bannerImg img {
    max-width: 100px;
    height: 100px;
  }

  #home-drivingImg-1 {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: 20px;
    right: 200px;
  }
}

@media (max-width: 467px) {
  .home-content-D h1 {
    font-size: 3.5rem;
  }
  .home-content-D p {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .button-style {
    padding: 8px 25px;
    font-size: 1.2rem;
  }
  .home-bannerbnt-sec span {
    font-size: 1.5rem;
  }
}
@media (max-width: 410px) {
  .home-content-D h1 {
    font-size: 3.2rem;
  }
}

/* //////////////////////////////////////// */
/* src/IntensiveDrivingCourse.module.css */
.IntensivecourseContainer {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(
    135deg,
    #ff7e5f,
    #feb47b
  ); /* Gradient background */
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 130px;
  max-width: 1240px;
  margin: 2rem auto;
  text-align: center;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.Intensivetitle {
  font-size: 3rem;
  color: #ffffff;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.IntensiveintroText {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 40px;

  transform: translateY(50px);
}

.IntensivecourseCards {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.IntensivecourseCard {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 30px;
  width: 250px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  transform: translateY(100px);
  cursor: pointer;
  overflow: hidden;
  text-align: left;
}

.IntensivecourseCard h3 {
  font-size: 1.5rem;
  color: #ff6f61;
  margin-bottom: 15px;
}

.IntensivecourseCard p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 20px;
}

.IntensivecourseCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.Intensivefooter {
  margin-top: 50px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: bold;

  transform: translateY(50px);
  animation: fadeIn 1s forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.Intensivebutton {
  background-color: #ff6f61;
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 30px;
}

.Intensivebutton:hover {
  background-color: #e55547;
  transform: scale(1.05);
}

.manualContent {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: linear-gradient(135deg, #aa3e00, hwb(36 41% 0%));
  border-radius: 20px;
}
.manualPara {
  background-color: rgba(255, 255, 255, 0.269);
  padding: 2rem 1rem;
  border-radius: 20px;
}
.manualPara p {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}
.ManualCorousel {
  font-family: "Antonio" sans-serif;
}
