* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --pink: hsl(329, 83%, 61%);
  --purple: hsl(274, 83%, 61%);
}
.loginRegisterPage {
  background-image: url(../../assets/images/bgDark.jpg);
  background-size: cover;
  font-family: "Roboto", Sans-serif;
  position: relative;
}
#whiteLogo {
  max-width: 300px;
  width: 100%;
}
.loginRegisterSection2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1140px;

  margin-left: auto;
  margin-right: auto;
}
.loginRegisterSection {
  max-width: 1140px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;
  padding-bottom: 2rem;
  position: sticky;
}

.loginheading {
  width: 100%;
  padding: 10px 10px 10px 10px;
  text-align: center;
}
.loginheading h1 {
  font-size: 4em;
  background: linear-gradient(45deg, var(--pink), var(--purple) 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 600;
}
.loginheading P {
  background: linear-gradient(45deg, var(--pink), var(--purple) 60%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 1.2rem;
}
.ImageDisplayFlex {
  padding: 2rem 1rem;
  display: flex;
  border-radius: 30px;
  gap: 20px;
  animation: slideFromLeft 1s ease-out;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}
@keyframes slideFromLeft {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.loginDisplayflexImage {
  width: 100%;
  max-width: 53%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.loginDisplayflexImage h2 {
  font-size: 2.7rem;
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
}

.loginDisplayflexImage img {
  max-width: 640px;
  width: 100%;
  height: 100%;
}
.loginDisplayflextext p {
  color: #b6b6b6;
  font-size: 1rem;
}
.loginDisplayflextext a {
  text-align: center;
  text-decoration: none;
  background: #5e5df0;
  border-radius: 999px;
  box-shadow: #5e5df0 0 10px 20px -10px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji",
    NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji",
    EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 10px 22px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}
.loginDisplayflextext a:hover {
  box-shadow: #d4d4ff 0 10px 20px -10px;
  background: #fbfbfd;
  border: 1px solid #5e5df0;
  color: #5e5df0;
  transition: ease-in-out 0.2s;
}
.loginLogo {
  text-align: center;
}
#loginLogo {
  max-width: 100px;
  border-radius: 50%;
  box-shadow: 0px 8px 16px rgba(204, 204, 204, 0.3);
}
.loginformContainer {
  width: 100%;
  max-width: 47%;
  border: 1px solid rgba(47, 85, 103, 0.853);
  background-color: #0002094f;
  border-radius: 15px;
}

.loginRegistration {
  padding: 20px 20px 20px 20px;
}

.loginRegistration form {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0px;
}

.loginRegistration h2 {
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.loginRegistration #level {
  display: flex;
  align-items: center;
}
.formControlWithIcon {
  max-width: 400px; /* Adjust space for the icon */
  width: 100%;
}

.loginFormsIcons {
  position: absolute;
  top: 50%;
  left: 370px; /* Position the icon */
  transform: translateY(-50%);
  z-index: 1;
  color: #0009b3;
  font-size: 18px;
  cursor: pointer; /* Ensure the icon is on top */
}

#registerAccount select {
  border: none;
  width: 100%;
  text-decoration: none;
  outline: none;
  padding: 10px;
  color: #000000;
}
#registerAccount select option {
  background-color: #02001c;
  color: white;
}
.formPrivacyPolicies {
  display: flex;

  margin-bottom: 1rem;
}
.formPrivacyPolicies input {
  cursor: pointer;
  color: #000000;
}
.formPrivacyPolicies p {
  margin-bottom: 0;
  color: #aaa;
  padding-left: 10px;
  font-size: 18px;
}
.loginFormBtn {
  text-align: center;
}
.loginFormBtn button {
  padding: 14px 80px;
  font-size: 16px;
  font-weight: 500;
  background-color: #ffffff;
  border-radius: 5px;
  color: #000000;
  border: none;
  width: 100%;
}
.loginFormBtn button:hover {
  background-color: #090909;
  color: white;
  transition: ease-in-out 0.2s;
}
.formFooter {
  margin-bottom: 1rem;
  text-align: center;
  padding: 10px;
}

.formFooter p {
  margin-bottom: 0;
  color: #757575;
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
}
.formFooter button {
  color: #fff;
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
  border: none;
  background-color: linear-gradient(45deg, var(--pink), var(--purple) 60%);
  padding-left: 10px;
}
.formFooter a {
  color: #fff;
  text-decoration: none;
}
.formFooter a:hover {
  color: #dadada;
}
@media (max-width: 967px) {
  .loginheading {
    width: 100%;
    padding: 15px;
  }
  .loginformContainer {
    max-width: 50%;
    width: 100%;
  }
  .loginFormsIcons {
    left: 330px;
  }
}
@media (max-width: 767px) {
  .loginRegisterSection2 {
    display: block;
  }
  .loginFormsIcons {
    left: 370px;
  }
  .loginheading {
    max-width: 100%;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
  .loginformContainer {
    max-width: 600px;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .ImageDisplayFlex {
    flex-direction: column;
  }
  .loginDisplayflexImage {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 458px) {
  .loginFormsIcons {
    left: 340px;
  }
}
@media (max-width: 438px) {
  .loginFormsIcons {
    left: 320px;
  }
}
@media (max-width: 410px) {
  .loginFormsIcons {
    left: 300px;
  }
}
@media (max-width: 390px) {
  .loginFormsIcons {
    left: 270px;
  }
}
@media (max-width: 370px) {
  .loginFormsIcons {
    left: 250px;
  }
}
.allbtns {
  margin: 2rem 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  gap: 10px;
  place-items: center;
}

.allbtns a button {
  font-size: 20px;
  font-weight: 800;
  min-width: 420px;
  cursor: pointer;
  letter-spacing: 0px;
  background-color: linear-gradient(45deg, var(--pink), var(--purple) 60%);
  border: 1px solid #ffffff;
  color: #ffffff;
  background: linear-gradient(45deg, var(--pink), var(--purple) 60%);
  padding: 18px 80px;
  transition: color 0.3s, border-color 0.3s, transform 0.3s;
}

.allbtns a button:hover {
  background-color: #dd0000af;
  transform: scale(1.1);
  animation: bounce 0.3s alternate;
}

@keyframes bounce {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.05); /* Slightly bigger size */
  }
}

.buttonsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  place-items: center;
  gap: 20px;
}
.buttonsGrid a {
  text-decoration: none;
}
.buttonsGrid a button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  min-width: 220px;
  cursor: pointer;
  letter-spacing: 0px;

  border: 1px solid #ffffff;
  color: #ffffff;
  background: linear-gradient(
    135deg,
    #ff6347,
    #ff1493
  ); /* Apply the gradient */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text; /* For non-webkit browsers */
  color: transparent;
  padding: 12px 50px;
  transition: color 0.3s, border-color 0.3s, transform 0.3s;
}
.buttonsGrid a button .gradientIcon {
  color: #ff7300;
  font-size: 30px; /* Apply the gradient */
}
.buttonsGrid a button .gradientIcon:hover {
  color: white;
}
.buttonsGrid a button:hover {
  transition: ease-in-out 0.2s;
  transform: scale(1.1);
  color: white;
}
@media (max-width: 767px) {
  .buttonsGrid a button {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px;
    min-width: 170px;
  }
  .buttonsGrid a button .gradientIcon {
    font-size: 20px; /* Apply the gradient */
  }
}

.registerBenefitsfeature {
  display: flex;
  align-items: flex-start;

  margin-bottom: 20px;
}

.registerBenefitsfeatureicon {
  font-size: 30px;
  margin-right: 15px;
}

.registerBenefitsfeaturecontent {
  max-width: 600px;
}

.registerBenefitsfeaturetitle {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 5px;
  color: white;
}

.registerBenefitsfeaturedescription {
  margin: 0;
  color: #d7f2ff;
}
