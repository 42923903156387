* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.imageSection {
  background-image: url(../../assets/images/theorySupportbanner.png);
  position: relative;
  width: 100%;
  padding: 10rem 0px;
  background-size: cover;
  background-position: center;
}
.maincontent {
  display: flex;
  max-width: 1640px;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.content {
  position: relative;
  width: 100%;
  color: white;
  text-align: left;
  padding: 1rem;
}

.heading1 h1 {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-bottom: 0.8rem;
}
.heading2 h2 {
  color: #ffffff;
  font-size: 5rem;
  font-weight: 700;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding-top: 0.8rem;
  padding-bottom: 0.5rem;
  margin-bottom: 20px;
}
#btn {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  border: 1px solid #7c7c7c;
  color: #ffffff;
  background: linear-gradient(135deg, #150071e8, #6574ffee);
  padding: 15px 60px 15px 60px;
  transition: color 0.3s, border-color 0.3s, transform 0.3s;
  border-radius: 6px; /* Added transform to transition */
}

#btn:hover {
  background: linear-gradient(135deg, #6574ffee, #150071e8);
  transform: scale(1.1); /* Grow size by 10% on hover */
  animation: bounce 0.3s infinite alternate; /* Applying bouncing animation */
}
.csrPagesLd {
  padding: 1rem;
  position: sticky;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background: linear-gradient(135deg, #00157199, #01764b76);
  border-radius: 6px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: 0 0 20px rgba(54, 54, 54, 0.656);
}
.csrPagesLd p {
  color: white;
  font-size: 1.1rem;
  margin-bottom: 0px;
  text-align: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
@keyframes bounce {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.05); /* Slightly bigger size */
  }
}

.video {
  position: relative;

  max-width: 50%;
  width: 100%;
  text-align: center;
  padding: 150px 150px 150px 150px;
}
.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #fff; /* Adjust color and opacity as needed */
  font-size: 170px;
  filter: drop-shadow(1px 0 6px rgba(0, 0, 0, 0.3));
}

.video img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 1020px) {
  .heading1 h1 {
    font-size: 1.2rem;
  }
  .heading2 h2 {
    font-size: 4.2rem;
  }
  .imageSection {
    padding: 8rem 0px;
  }
}
@media (max-width: 767px) {
  .imageSection {
    padding: 6rem 0px;
  }
  .heading1 h1 {
    font-size: 1rem;
    letter-spacing: 10px;
  }
  .heading2 h2 {
    font-size: 3.5rem;
  }

  .heading1 {
    padding-top: 1rem;
  }
  .content {
    max-width: 100%;
  }
  .video {
    max-width: 100%;
  }
}
@media (max-width: 627px) {
  .imageSection {
    padding: 5rem 0px;
  }
  .heading2 h2 {
    font-size: 3rem;
  }
}
@media (max-width: 487px) {
  .imageSection {
    padding: 3rem 0px;
  }
  .heading1 h1 {
    font-size: 1rem;
    letter-spacing: 8px;
  }
  .heading2 h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 427px) {
  .heading2 h2 {
    font-size: 2rem;
  }
}
@media (max-width: 350px) {
  .video {
    padding: 40px;
  }
}
/* /////////////////////////why choose us css/////////// */
.features {
  padding: 2rem 0;
}
.features h4 {
  color: white;
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2em;
  text-align: center;

  margin-bottom: 20px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.features p {
  color: white;
  line-height: 28px;
  text-align: center;
  font-weight: 400;

  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.mainFeatures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;

  text-align: center;
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
}
.column {
  padding: 15px 15px 15px 15px;
  background-color: black;
  border-radius: 20px;
  border: 1px solid white;
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);

  transition: box-shadow 0.3s ease;
  opacity: 0;
  transform: translateY(100%);
  animation: slideUp 7s forwards;
}
.column:nth-child(1) h3 {
  color: red;
}
.column:nth-child(2) h3 {
  color: #fbc809;
}
.column:nth-child(3) h3 {
  color: #cb2c92;
}
.column:nth-child(4) h3 {
  color: #008081;
}
.column:nth-child(1) p {
  color: red;
}
.column:nth-child(2) p {
  color: #fbc809;
}
.column:nth-child(3) p {
  color: #cb2c92;
}
.column:nth-child(4) p {
  color: #008081;
}

.column:nth-child(1) {
  animation-delay: 0s;
}

.column:nth-child(2) {
  animation-delay: 1s;
}

.column:nth-child(3) {
  animation-delay: 2s;
}
.column:nth-child(4) {
  animation-delay: 3s;
}
.column:nth-child(1):hover {
  border: 1px solid #d60f20;
}
.column:nth-child(2):hover {
  border: 1px solid #d6d30f;
}
.column:nth-child(3):hover {
  border: 1px solid #d60fab;
}
.column:nth-child(4):hover {
  border: 1px solid #00c4a3;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.column:hover {
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.203);
}
.column span {
  font-size: 50px;
  color: white;
  display: inline-block;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
}
.column img {
  max-width: 150px;
  width: 100%;
}
.column h3 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
  color: white;
}
.column p {
  font-size: 16px;

  font-weight: 300;
  line-height: 1.7em;
  color: white;
}
#featuresIcon {
  transition: transform 0.3s ease;
}
#featuresIcon:hover {
  transform: scale(1.2);
  color: #eb0009;
}
@media (max-width: 1085px) {
  .features h4 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .features h4 {
    font-size: 30px;
  }
}
/* /////////////////////benefits css/////////////////////////// */

.benefitsSection {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 2rem 0;
}
.benefitsHeading h4 {
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 65px;
  font-weight: 900;
  line-height: 1.2em;
  margin-bottom: 2.5rem;
}
.benefitsDetails ul {
  padding: 3rem;
  border-radius: 6px;
  background: linear-gradient(135deg, #00157199, #01764b76);
}
.benefitsDetails {
  text-align: justify;
}
#benefitsList {
  display: flex;
  text-align: justify;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  align-items: center;
  padding-bottom: 1.2rem;
}

#checkCircle {
  color: #eb0062;
  width: 1.25em;
  padding-right: 10px;
  font-size: 30px;
  font-weight: 900;
}
#benefitsList p {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  padding-left: 5px;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .benefitsHeading h4 {
    font-size: 45px;
  }
  #benefitsList p {
    font-size: 16px;
  }
  #benefitsList {
    text-align: left;
    justify-content: flex-start;
  }
}
@media (max-width: 428px) {
  .benefitsHeading h4 {
    font-size: 30px;
  }
  #benefitsList p {
    font-size: 12px;
  }
  #benefitsList {
    justify-content: flex-start;
  }
}
@media (max-width: 263px) {
  .benefitsHeading h4 {
    font-size: 23px;
  }
  #benefitsList p {
    font-size: 12px;
  }
}

/* ///////////////////join our frenchise /////////////////////////////// */
.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 50px 0px 50px 0px;
  background: linear-gradient(135deg, #00157199, #01764b76);
}

.helpingHand {
  font-size: 95px;
  color: #fff;
}
.headingDiv h1 {
  line-height: 1;
  font-size: 50px;
  font-weight: 600;
  color: #fff;
}
.buttonDiv a {
  text-decoration: none;
}
#buttonDiv {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;

  color: #fff;
  border: 1px solid #616161;

  background-color: #00013d;
  border-radius: 6px;
  padding: 15px 45px 15px 45px;
  transition: all 0.3s ease;
  margin-top: 20px;
}
#buttonDiv:hover {
  background-color: #500202;

  transform: scale(1.1);
}

.paragraphsDiv {
  margin-top: 30px; /* Adjust margin as needed */
  color: #fff;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Keyframes for sliding animation */
@keyframes slideFromLeft {
  from {
    transform: translateX(-2000%);
  }
  to {
    transform: translateX(0);
  }
}
@media (max-width: 460px) {
  .headingDiv h1 {
    font-size: 30px;
  }
}

/* /////////////////////////////Our beloved pupils ////////////////// */
.belovedCustomerSection {
  display: flex;
  max-width: 1640px;
  border-radius: 20px;
  align-items: center;
  width: 100%;
  padding: 4rem 0;
  text-align: center;
  margin: 2rem auto;
  background: linear-gradient(135deg, #001885, #da61ff);
}
.belovedCustomerdetails {
  width: 66%;
  margin: 0% 5% 0% 5%;
}
.belovedCustomerdetails h1 {
  color: white;
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 20px;
}
.belovedCustomerdetails h1 span {
  color: #00e3eb;
}
.belovedCustomerdetails p {
  line-height: 28px;
  text-align: justify;
  color: white;

  font-size: 1.2rem;
  font-weight: 400;
}
.belovedCustomerdetails p a {
  text-decoration: none;
  color: #eb0009;
}
.pupilsImg {
  width: 44%;
  padding: 10px;
}
.pupilsImg img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 255, 0.8));
}
@media (max-width: 1020px) {
  .belovedCustomerdetails h1 {
    font-size: 32px;
  }
  .belovedCustomerdetails p {
    font-size: 15px;
  }
  .belovedCustomerdetails {
    width: 60%;
  }
  .pupilsImg {
    width: 40%;
  }
}
@media (max-width: 777px) {
  .belovedCustomerSection {
    display: block;
  }
  .belovedCustomerdetails {
    width: 100%;
    margin: 0;
  }
  .pupilsImg {
    width: 100%;
  }
  .belovedCustomerdetails h1 {
    font-size: 55px;
  }
  .belovedCustomerdetails p {
    font-size: 1.2em;
    line-height: 22px;
    padding: 0px 20px 0px 20px;
  }
}
@media (max-width: 468px) {
  .belovedCustomerdetails h1 {
    font-size: 32px;
  }
  .belovedCustomerdetails p {
    font-size: 15px;
    padding: 0px 20px 0px 20px;
  }
}
/* //////////////////////////////Booking section css//////////////////////// */
/* YourComponent.module.css */

.bookingSection {
  display: flex;
  align-items: center;
  width: 100%;

  align-items: center;
  justify-content: center;
  max-width: 1640px;
  border-radius: 20px;
  align-items: center;
  width: 100%;
  padding: 4rem 2rem;
  text-align: center;

  margin: 0rem auto;
  background: linear-gradient(135deg, #da61ff, #001885);
}

.diaryImgSection {
  flex: 1;
  margin-right: 20px; /* Adjust spacing between image and text */
  max-width: 50%;
  width: 100%;
}

.diaryDetailsSection {
  flex: 2;
  max-width: 50%;
  width: 100%;
}

.diaryImgSection img {
  max-width: 100%;
  height: auto;
}

.diaryDetailsSection h4 {
  color: white;
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 20px;

  text-align: center;
}

.diaryDetailsSection p {
  text-align: center;
  color: white;

  font-size: 1.3em;
  font-weight: 500;
  line-height: 28px;
}
@media (max-width: 1024px) {
  .diaryDetailsSection p {
    font-size: 19px;
  }
}
@media (max-width: 777px) {
  .bookingSection {
    display: block;
  }
  .diaryImgSection {
    max-width: 100%;
    width: 100%;
  }
  .diaryDetailsSection {
    max-width: 100%;
    width: 100%;
  }
  .diaryDetailsSection h4 {
    margin-top: 1rem;
  }
  .diaryDetailsSection p {
    padding: 0px 20px 0px 20px;
    font-size: 16px;
  }
}
@media (max-width: 428px) {
  .diaryDetailsSection h4 {
    font-size: 35px;
  }
  .diaryDetailsSection p {
    line-height: 25px;
    font-size: 16px;
  }
}
/* /////////////////////testimonials ///////////////// */
.testimonialsContainer {
  background-color: #eb0009;
  padding: 100px 0;
}
.tmcontainer {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.tmcontainer h4 {
  color: #ffffff;
  font-size: 65px;
  font-weight: 600;
}
.testimonialContent {
  background-color: #fff;
  padding: 1rem 2rem;
}
.testimonialName {
  color: #eb0009;
  font-size: 1.75rem;
  font-weight: 700;
}
.testimonialComment {
  font-size: 1.3em;
  font-style: italic;
  line-height: 28px;
  text-align: center;
}
.testimonialImage {
  height: auto;
  max-width: 100%;
  border-radius: 50%;
}
@media (max-width: 1200px) {
  .testimonialsContainer {
    padding: 100px 30px 100px 30px;
  }
}
@media (max-width: 500px) {
  .tmcontainer h4 {
    font-size: 45px;
  }
  .testimonialComment {
    font-size: 16px;
  }
}
/* //////////////reveiw section ///////////////////// */
/* /////////////// reviews section ///////////////// */
.facebookReviewsSection {
  text-align: center;
  padding: 2rem 0;
}

.facebookReviewsContainer {
  padding: 2rem 0;
  background-color: red;
}
.facebookReviewsContainer hr {
  border: 2px solid #fff;

  opacity: 1;
  text-align: center;
  width: 30%;
  margin: 0 auto;
}

#heading1 {
  color: white;
  font-family: "Arial", Sans-serif;
  font-size: 65px;
}

.reviewsList {
  display: grid;
  grid-gap: 20px;
}

.review {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.followUsLinks {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}
.followUsLinks hr {
  border: 1.05px solid #fff;
  text-align: center;
  width: 38%;
  margin: 0 auto;
}
.followUsLinks h4 {
  margin-top: 1.8rem;
  font-family: "Arial", Sans-serif;
  color: #ffffff;
  line-height: 1;
  font-size: 2.8em;
  font-weight: 600;
  margin-bottom: 1.8rem;
}
.socialFollowIcons {
  display: flex;
  justify-content: space-evenly;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: 2rem 0;
}

.socialFollowIcons a {
  display: inline-block;
  background-color: white;
  border-radius: 6px;
  padding: 8px 10px 8px 10px;

  transition: transform 0.3s;
}
#FollowIcons {
  color: #eb0009;
  font-size: 1.7rem;
}
.socialFollowIcons a:hover {
  transform: translateY(-5px);
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333; /* Change color as needed */
}
@media (max-width: 770px) {
  .facebookReviewsSection #heading1 {
    font-size: 45px;
  }
  #heading2 {
    font-size: 45px;
  }
  .followUsLinks h4 {
    font-size: 2.8em;
  }
}

/* /////////////////////testimonials ///////////////// */
.testimonialsContainer {
  text-align: center;
  padding: 70px 0;
  font-family: "Antonio", sans-serif;
}
.tmcontainer {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}
.tmcontainer h4 {
  color: white;
  font-size: 65px;
  font-weight: 600;
  text-align: center;
}
.testimonialContent {
  background-color: #eb0009;
  padding: 2rem;
}
#comma1 {
  text-align: left;
  font-size: 3rem;
  color: white;
}
#comma2 {
  text-align: right;
  font-size: 3rem;
  color: white;
}
.testimonialName {
  text-align: center;
  color: white;
  font-size: 1.75rem;
  font-weight: 700;
}
.testimonialComment {
  text-align: center;
  font-size: 1.3em;
  color: white;
  line-height: 28px;
  text-align: center;
}
.testimonialImage {
  text-align: center;
  height: auto;
  max-width: 100%;
  border-radius: 50%;
}
@media (max-width: 1200px) {
  .testimonialsContainer {
    padding: 100px 30px 100px 30px;
  }
}
@media (max-width: 500px) {
  .tmcontainer h4 {
    font-size: 45px;
  }
  .testimonialComment {
    font-size: 16px;
  }
}
.imageSliderContainer h2 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: white;
  text-align: center;
}

.imgSlider img {
  height: 450px;
  width: 100%;
}
