@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slide, .slide img{
    max-width: 400px;
    width: 100%;
   
    aspect-ratio: 1/1;
    object-fit: contain;
}
.slide{
    transition: .5s;
    transform: scale(.7);
    filter:blur(5px);
}
.slide-active{
    filter:blur(0px);
opacity: 1;
transform: scale(1.1);
}