#navContainer {
  position: relative;
  z-index: 100000;
}
.navContainer1{
  background-color: rgba(0, 20, 32, 0.877);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
 
  align-items: center;
  gap: 10px;
  
 
  padding: 15px 20px;
  width: 100%;
}
.headerLinksSection {
  width: 100%;
}
.navLinks {
  display: flex;
  justify-content: flex-end;
}
.imgLogoSection {
  text-align: center;
  width: 30%;
}
.imgLogoSection .imgLogoSection1 img {
  max-width: 70px;
  width: 100%;
  border-radius: 50%;
}
.imgLogoSection .imgLogoSection2 img {
  max-width: 300px;
  width: 100%;
}
#navLinksUl {
  display: flex;
  gap: 20px;
  align-items: center;
}
.contactInfo span {
  font-size: 1.2rem;
  color: white;
  padding: 0px 1rem;
}
.contactInfo a {
  position: relative;
  display: inline-block;
  font-size: 1.2rem;
  color: rgb(235, 243, 253);
  font-weight: 500;
  text-decoration: none;
}
.contactInfo a::after {
  content: "";
  position: absolute;
  bottom: 0;
  margin-top: 5px;
  left: 0;
  width: 0;
  height: 2px; /* Line thickness */
  background-color: rgb(2, 54, 86);
  /* Line color */
  transition: width 0.3s ease; /* Controls the speed of the animation */
}
.contactInfo a:hover {
  color: rgb(148, 191, 255);
}
.contactInfo a:hover::after {
  width: 100%;

  /* Full width on hover */
}
.navLinks ul {
  padding-left: 0;
}
.navLinks ul li {
  font-size: 1.2rem;
  color: rgb(235, 243, 253);
  font-weight: 500;
}
.navShoppingCart {
  font-size: 1.7rem;
  color: rgb(235, 243, 253);
  font-weight: 500;
}
.navLinks ul button {
  padding: 2px 12px;
  background-color: rgba(1, 19, 30, 0.944);
  border: 1px solid rgba(1, 43, 69, 0.642);
  border-radius: 6px;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
}
.navLinks ul button:hover {
  border: 1px solid rgb(2, 69, 110);
}
.navShoppingCart {
  display: inline-block;
  position: relative;
}

.navShoppingCart a {
  display: inline-block;
  position: relative;
  color: white;
  padding: 3px 9px;
  border: 1px solid rgb(56, 83, 117);
  border-radius: 50%;
}

.shoppincartIcon {
  font-size: 28px; /* Adjust the size of the icon */
  color: inherit; /* Icon color, inherited from parent */
}

.navShoppingCart a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border: 2px solid rgb(2, 54, 86); /* Circle color */
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease; /* Smoothly animate the size */
  transform: translate(-50%, -50%); /* Center the circle */
}

.navShoppingCart a:hover::before {
  width: 120%; /* Circle size increases on hover */
  height: 120%;
}
@media (max-width: 1068px) {
  .contactInfo a {
    font-size: 17px;
  }
  .navLinks ul button {
    font-size: 17px;
  }
}
@media (max-width: 950px) {
  .imgLogoSection {
    display: none;
  }
}
@media (max-width: 668px) {
  .contactInfo a {
    font-size: 16px;
  }
  .navLinks ul button {
    font-size: 16px;
  }
  .contactInfo span {
    font-size: 1rem;
    color: white;
    padding: 0px 8px;
  }
  .navLinks ul {
    margin-bottom: 0px;
  }
}
@media (max-width: 520px) {
  .contactInfo span {
    display: none;
  }
  .navShoppingCart {
    font-size: 17px;
  }
}
@media (max-width: 400px) {
  .contactInfo a {
    font-size: 14px;
  }
}
