.accordionContainer {
    max-width: 800px;
    width: 100%;
    margin: 0pc auto;
    background: linear-gradient(135deg, #65ccff, #01b69b);
    border-radius: 5px;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .LessonAccordionheader {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(135deg, #ed006b, #9e00ce);
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 20px;
    
    align-items: center;
  }
  
  .LessonAccordionProgress {
    font-size: 1.1rem;
  }
  
  .listLessonData {
    list-style: none;
    margin: 0;
    padding: 0;
    color: black;
  }
  
  .itemLesson {
    padding:  20px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
   
    font-size: 1.1rem;
    font-weight: 600;
    position: relative;
  }
  
  .listLessonData a{
    text-decoration: none;
    color: black;
  }
  
  .itemLesson:hover {
    background: #cafff9f0;
    padding-left: 20px;
    box-shadow: inset 0 0 10px #6dc0c1;
    
  }
  
  .lessonOpen {
    background: #e9e9c6;
    transition: ease-in-out 0.3s;
    
  }
  
  .lessonRow {
    display: flex;
    align-items: center;

  }
  
  .checkIcon {
     font-size: 1.5rem;
     color: #540037;
     margin-right: 12px;
  }
  
  .quizIcon {
   font-size: 1.5rem;
   margin-right: 12px;
   color: #540037;
  }
  
  .description {
    
    font-size: 14px;
    color: #555;
    margin-top: 5px;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.3s ease;
  }
  
  .lessonOpen .description {
    opacity: 1;
    max-height: 100px;
    transition: ease-in-out 0.3s;
  }
  