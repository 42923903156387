* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.TheHonestTruth {
  background-color: black;
  padding-bottom: 10rem;
  padding-top: 1rem;
  font-family: "Antonio", sans-serif;
}
.csrPageFront {
  background: url("../../assets/images/thehonesttruthbanner.jpg");
  color: white;
  background-size: cover;
  background-position: top;
  border-radius: 10px;
  padding: 10rem 1rem;
  position: relative;
}
.eCSfrontSection {
  max-width: 1640px;
  margin: 0px auto;
  color: white;
  position: sticky;
  padding: 2rem 1rem;

  margin-bottom: 1rem;
}
.eCSfrontSection h2 {
  font-size: 5rem;
  margin-bottom: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}

.eCSfrontSection img {
  margin: 2rem 0px;
  max-width: 400px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
.csrPagesLd {
  padding: 1rem;
  position: sticky;
  max-width: 1000px;
  margin-left: auto;
  margin-right: 44%;
  width: 100%;
  background: linear-gradient(135deg, #33007299, #76014776);
  border-radius: 6px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.656);
}
.csrPagesLd p {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0px;
  text-align: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
@media (max-width: 780px) {
  .eCSfrontSection h2 {
    font-size: 4.2rem;
  }
  .eCSfrontSection p {
    font-size: 1rem;
  }
}

@media (max-width: 428px) {
  .eCSfrontSection h2 {
    font-size: 3.5rem;
  }
}
.THTSec {
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
}
.videosContentSections {
  padding: 2rem 0px;
}
.videosContentSections h2 {
  color: white;
  text-align: center;
  font-size: 3rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  padding: 2rem 0px;
}

.innerTheorySupportContent {
  max-width: 800px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.theorySupportContentVideo iframe {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 1;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(116, 116, 116, 0.656);
}
.theorySupportContentVideosec {
  padding: 1rem 1rem;
  max-width: 800px;
  width: 100%;
  margin-right: auto;

  margin-left: auto;
}
.theorySupportContentVideosec video {
  width: 100%;
}
.textThtSection {
  padding: 2rem 0px;
}
.textThtSection h2 {
  font-size: 3rem;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: #04c3b6;
}
.textThtSection h2 span {
  color: #9704c3;
}
.textThtSection hr {
  opacity: 1;
  border: 2px solid rgb(219, 160, 255);
}
#textThtSection {
  text-align: center;
  max-width: 1440px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}
#textThtSection p {
  font-size: 1.2rem;
  color: white;
  background: linear-gradient(135deg, #33007299, #76014776);
  padding: 1rem;
  border-radius: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
#textThtSection button {
  padding: 7px 1.5rem;
  font-size: 1.5rem;
  color: white;
  border: none;
  border-radius: 10px;
  background: linear-gradient(135deg, #33007299, #76014776);
  margin: 2rem 0px;
}
#textThtSection button:hover {
  border: 1px solid rgb(124, 124, 124);
  background-color: #8a0305cd;
}
@media (max-width: 780px) {
  .videosContentSections h2 {
    font-size: 2.5rem;
  }
  .textThtSection h2 {
    font-size: 2.5rem;
  }
  #textThtSection p {
    font-size: 1rem;
  }
  #textThtSection button {
    font-size: 1.2rem;
  }
}

@media (max-width: 428px) {
  .videosContentSections h2 {
    font-size: 2rem;
  }
  .textThtSection h2 {
    font-size: 2rem;
  }
}
/* //////////////////////////////////////////// */
.getInvolved {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  background: linear-gradient(135deg, #33007299, #76014776);
  padding: 2rem;
  border-radius: 20px;
}
#getInvolvedHead h2 {
  font-size: 3rem;
  text-align: center;
  color: white;
}
#getInvolvedHead hr {
  opacity: 1;
  border: 2px solid rgb(171, 255, 240);
}

#downwordArrow {
  color: white;
  font-size: 5rem;
}
.getInvolvedContentDiv {
  padding: 1rem 0px;
}

.getInContentSection {
  text-align: center;
  background: linear-gradient(135deg, #00847499, #76014776);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
}
#downwordArrow {
  margin: 1rem 0px;
}
.getInContentSection h2 {
  font-size: 2rem;
  color: white;
  text-align: center;
}
.getInContentSection hr {
  opacity: 1;
  border: 1px solid rgb(158, 255, 211);
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.getInContentSection p {
  color: white;

  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}
/*//////////////////////////// Smartlearner stats /////////////////////////////// */
.smartleanerStats {
  background: linear-gradient(135deg, #33007299, #76014776);
}
.smartleanerStatsSection {
  padding: 2rem 1rem;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
}
.smartleanerStatsSection hr {
  max-width: 500px;
  width: 100%;
  color: #fff;
  border: 2px solid #fff;
  margin-right: auto;
  margin-left: auto;
  opacity: 1;
}
.smartleanerStatsSection h3 {
  font-size: 65px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2rem;
}
.smartleanerStatsSection P {
  color: #fff;
  margin-bottom: 1rem;
  letter-spacing: 6px;
}
.statsContaier {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.numberContainer {
  padding: 45px;

  border: 1px solid #fff;

  width: 100%;
}
.numberContainer span {
  color: #fff;
  font-size: 80px;
  font-weight: 500;
}
.numberContainer p {
  color: #fff;
}
#statslowerheading {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  line-height: 2.5;
}
@media (max-width: 728px) {
  .statsContaier {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .smartleanerStatsSection h3 {
    font-size: 35px;
  }
  .numberContainer {
    padding: 30px;
  }
  .numberContainer span {
    font-size: 45px;
  }
}
/* ///////////////////////////////////////////////////// */
.ourInvolmentSection {
  padding: 3rem 0px;
  text-align: center;
}
.ourInvolmentDiv {
  padding: 1rem;
  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.ourInvolmentDiv h2 {
  color: #ff121a;
  font-size: 3rem;
  margin-bottom: 1rem;
}
.ourInvolmentDiv hr {
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  opacity: 1;
  margin-bottom: 1rem;
  border: 2px solid #ff121a;
}
.ourInvolmentDiv p {
  margin-bottom: 1rem;
}
@media (max-width: 528px) {
  .ourInvolmentDiv h2 {
    font-size: 1.7rem;
  }
}
/* ////////////////////////////////////////////////////////////// */

.features h4 {
  color: #04c3b6;
  padding: 2rem 0px;
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 4rem;
  text-align: center;
}
.features h4 span {
  color: #9a04c3;
}
@media (max-width: 728px) {
  .features h4 {
    font-size: 3.5rem;
  }
}
@media (max-width: 428px) {
  .features h4 {
    font-size: 3rem;
  }
}
