* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
p {
  margin: 0px;
}
.ADITrainingPortalPage {
  font-family: "Antonio", sans-serif;
  background-color: black;
  padding: 1rem 0px 2rem;
}
.adiPortalPageFirstdiv {
  position: relative;
  background: url("../../assets/images/pdi-bannerss.jpg");
  background-size: cover;
  background-position: left;
  border-radius: 10px;
  padding: 170px 0;
}
.opicity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000000ba;
}
.adiPortalPageFirstdiv section {
  position: sticky;
  padding: 1rem;
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
}

.adiPortalPageFirstdiv section h2 {
  font-size: 5rem;
  padding-bottom: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;

  margin: 0px auto;

  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.gGpFrontListP p {
  color: white;
  background: linear-gradient(135deg, #944907, #760136b5);
  font-size: 1.2rem;
  padding: 1rem;
  position: sticky;
  max-width: 900px;
  margin-left: auto;
  margin-right: 44%;
  width: 100%;

  border-radius: 6px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.656);

  margin-bottom: 1rem;
}
@media (max-width: 950px) {
  .adiPortalPageFirstdiv section h2 {
    font-size: 4rem;
  }
}

@media (max-width: 750px) {
  .adiPortalPageFirstdiv section h2 {
    font-size: 3.5rem;
  }
  .gGpFrontListP p {
    font-size: 1rem;
  }
}
@media (max-width: 550px) {
  .adiPortalPageFirstdiv section h2 {
    font-size: 3rem;
  }
}
@media (max-width: 450px) {
  .adiPortalPageFirstdiv section h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 400px) {
  .adiPortalPageFirstdiv section h2 {
    font-size: 2rem;
  }
}
.adiPortalPageSeconddiv {
  text-align: center;
}
.adiPortalPageSeconddiv img {
  width: 100%;
}
.paraSecHandImg {
  text-align: center;
  padding: 2rem 0px;
}
.paraSecHandImg img {
  max-width: 400px;
  width: 100%;
  animation: slideFromBottomH2 2s ease-in-out;
}

.paraSecHandImg p {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  padding: 0px 1rem;

  color: #ffffff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  animation: slideFromBottomH2 2s ease-in-out;
}
@media (max-width: 860px) {
  .paraSecHandImg p {
    font-size: 2rem;
  }
}
@media (max-width: 720px) {
  .paraSecHandImg p {
    font-size: 1.5rem;
  }
}
@media (max-width: 520px) {
  .paraSecHandImg p {
    font-size: 1.2rem;
  }
}
@media (max-width: 720px) {
  .adiPortalPageSeconddiv p {
    font-size: 2rem;
  }
}
@media (max-width: 560px) {
  .adiPortalPageSeconddiv p {
    font-size: 1.5rem;
  }
}
@media (max-width: 430px) {
  .adiPortalPageSeconddiv p {
    font-size: 1.2rem;
  }
}
.adiPortalPageSeconddiv img {
  animation: slideFromBottom 1s ease-in-out;
}

@keyframes slideFromBottomH2 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slideFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.adiPortalPageThirddiv {
  background-color: black;
  text-align: center;
  padding: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.adiPortalPageThirddiv h2 {
  padding: 2rem 0px;
  font-size: 3.5rem;
  font-weight: 700;
  color: #ffffff;
}
.adiPortalPageThirddivh2 {
  background: linear-gradient(135deg, #f67504, #ce033c);
  max-width: 600px;
  margin: 2rem auto;
  border-radius: 10px;
  width: 100%;
}

.adiLearningSupport h2 {
  padding: 2rem 0px;
  font-size: 3.5rem;
  font-weight: 700;
  color: #ffffff;
  animation: slideFromBottom 2s ease-in-out;
}

.adiPortalVideo iframe {
  border-radius: 30px;
  border-radius: 15px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0px 0px 55px 5px rgba(255, 255, 255, 0.171);
}
#hrAdiLearningText {
  display: flex;
  align-items: center;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
#hrAdiLearningText p span {
  color: #f50f6b;
}
#hrAdiLearningText p {
  width: 100%;
  max-width: 300px;
  margin-bottom: 0px;
  color: hsl(209, 100%, 86%);
  font-size: 1.1rem;

  animation: slideFromBottom 2s ease-in-out;
}
#hrAdiLearningText hr {
  margin: 0;
  width: 100%;

  border-top: none;
  opacity: 1;
}
.adiTrainingPortalLearning {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  max-width: 1340px;
  margin-right: auto;
  margin-left: auto;
  gap: 20px;
  margin-top: 1rem;
}
.adiTrainingPortalLearningInnerContent {
  margin: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
  opacity: 0;
  transform: translateY(100%);
  animation: slideUp 7s forwards;
}
.adiTrainingPortalLearningInnerContent:nth-child(1) {
  animation-delay: 0s;
  background: linear-gradient(135deg, #6fff00, #0e4d15);
}

.adiTrainingPortalLearningInnerContent:nth-child(2) {
  animation-delay: 1s;
  background: linear-gradient(135deg, #f67504, #ce033c);
}

.adiTrainingPortalLearningInnerContent:nth-child(3) {
  animation-delay: 2s;
  background: linear-gradient(135deg, #3baff7, #08009a);
}
@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.adiTrainingPortalLearningInnerContent h2 {
  font-size: 2rem;
}
#adiPortalFaIcons {
  color: rgb(0, 185, 0);
  border-color: rgb(0, 185, 0);
  font-size: 100px;
  transition: transform 0.3s ease;
}
#adiPortalFaIcons:hover {
  color: #006ace;
  transform: scale(1.1);
}
.adiPortalBtnLearning {
  padding: 15px 30px;
  border: 1px solid rgb(0, 185, 0);
  border-radius: 6px;
  background-color: black;
  color: #fff;
  font-size: 1.1rem;

  transition: 0.3s ease-in;
}
.adiPortalBtnLearning:hover {
  background: linear-gradient(135deg, #f6f204, #ff7da2);
  color: rgb(41, 0, 13);
  border: none;
  
}
.adiPortalFooterSection {
  text-align: center;
}
.adiFooterAddressInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  line-height: 30px;
  padding-top: 1rem;
}
.adiFooterAddressInfo p {
  margin-bottom: 0px;
}
.adiPortalFooterSection {
  padding: 2rem 0px;
  border-radius: 20px;
  background: linear-gradient(135deg, #3baff7, #08009a);
}
.adiPortalFooterInnerContainer img {
  padding: 1rem 0px;
  max-width: 400px;
  width: 100%;
}
.adiFooterContactInfo {
  line-height: 40px;
}
.adiFooterContactInfo a {
  text-align: center;
  font-size: 1.3rem;
  text-decoration: none;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

}
.adiFooterContactInfo a:hover {
  color: rgb(0, 185, 0);
}
.adiFooterAddressInfo p {
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

}
#adiFaIcons {
  color: #fff;
}
.adiSocialIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  width: 100%;
  padding-top: 2rem;
}
#adiSocialFaIcons {
  font-size: 2rem;
  color: rgb(0, 185, 0);
}
#adiSocialFaIcons:hover {
  color: #fff;
  transition: 0.3s ease-in-out;
}
@media (max-width: 500px) {
  .adiPortalPageSeconddiv h2 {
    font-size: 3rem;
  }
  .adiPortalPageThirddiv h2 {
    font-size: 3rem;
  }
  .adiTrainingPortalLearningInnerContent h2 {
    font-size: 1.5rem;
  }
  .adiTrainingPortalLearningInnerContent {
    margin: 0px;
  }
}
/* ///////////////////////partners section /////////////////// */
/* ///////////////////////partners section /////////////////// */
.ourPartnersSection {
  background-image: url("../../assets/images/ourpartners.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
.bgOverlay {
  background-color: black;
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
}
.ourPartnersSection h2 {
  color: #fff;
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  position: sticky;
}
.partnerSection {
  padding: 2rem 1rem;
  margin-top: 3rem;
  background-color: #ffffffe7;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  place-items: center;
}

.partnerSection img {
  width: 150px;
  aspect-ratio: 1/1;
  margin: 0px 1rem;
  object-fit: contain;

  position: sticky;
}

@media (max-width: 934px) {
  .partnerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
@media (max-width: 780px) {
  .partnerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 428px) {
  .ourPartnersSection h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: 800;
    text-align: center;
  }
  .partnerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }
}
