.newsContainers {
  background-color: #202020;
}
.newsSection {
  display: flex;
  max-width: 1500px;
  margin: 0px auto;
  gap: 1rem; /* Space between the two sections */
  padding: 2rem 1rem;

  color: white;
}

/* Top Stories Section */
.topStories {
  width: 63%;
  background-color: #141414cd;

  border-radius: 8px;
  padding: 2rem;
}

.slide {
  padding: 1rem 0px;
}

.storyCard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  align-items: center;
  width: 100%;
}
.storiesFevicon a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}
.favicon {
  max-width: 50px;
  width: 100px;
  border-radius: 50%;
  margin-right: 10px;
}

.newsBannerImage {
  width: 100%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 1rem;
}
.imgSection a h3:hover {
  color: red;
}

.socialFollowIcons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

  gap: 20px;
  padding: 2rem 0;
  max-width: 350px;
  width: 100%;
}

.socialFollowIcons a {
  display: inline-block;
  
  border-radius: 50%;
  padding: 8px 10px 8px 10px;
  
  transition: transform 0.3s;
  width: 47px;
}

#FollowIcons {
  font-size: 1.7rem;
}

.socialFollowIcons a:hover {
  transform: translateY(-5px);
  background-color: rgb(0, 0, 0);
  color: white;
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333;
}
/* Local News Section */
.localNews {
  width: 37%;
  background-color: #141414cd;
  padding: 2rem;
  border-radius: 15px;
}

.newsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 700px;
  overflow-y: scroll;
  width: 100%;
}
.newsList a{
    color: white;
    text-decoration: none;
    transition: transform 0.3s ease;
}
.newsList a:hover {
    transform: scale(1.06);
    
  }
.newsCard {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 8px;
  background-color: #1e1e1e;
}
.newsCard h4 {
  font-family: "Google Sans", sans-serif;
  font-size: 1.3rem;
}
.imageDate {
  border-radius: 8px;
  max-width: 50px;
  height: 50px;
  width: 100%;
}
.newsImage {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
}
.storiesDetails {
  background-color: #202020;
  padding: 1rem;
  border-radius: 15px;
}
.storiesDetails p{
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    font-family: "Google Sans", sans-serif;
}

@media (max-width: 1200px) {
  .newsCard h4 {
    font-size: 1.2rem;
  }
}
@media (max-width: 1100px) {
  .imgSection h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1000px) {
  .newsCard h4 {
    font-size: 1rem;
  }
}
@media (max-width: 800px) {
  .newsSection {
    flex-direction: column;
  }
  .topStories {
    width: 100%;
  }
  .localNews {
    width: 100%;
  }
}
