* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* /////////////// reviews section ///////////////// */
.facebookReviewsSection {
  text-align: center;

  background: linear-gradient(45deg, #33036a, #592ee5);
}

.facebookReviewsContainer {
  padding: 1rem 0;
}

.facebookReviewsContainer span hr {
  border: 2px solid #fff;
  opacity: 1;
  width: 900px;
  margin: 0px auto;
  text-align: center;
}

#heading1 {
  color: red;
  
  font-family: "Antonio", Sans-serif;
  font-size: 5rem;
  font-weight: 900;
}

.reviewsList {
  display: grid;
  grid-gap: 20px;
}

.review {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.followUsLinks {
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 1rem;
}

.followUsLinks hr {
  border: 4px solid red;
  text-align: center;
  height: 70px;
  font-weight: 900;
  margin: 0 auto;
  opacity: 1;
}

.followUsLinks h4 {
  margin-top: 1rem 0px;

  color: #ffffff;
  line-height: 1;
  font-size: 4rem;
  font-weight: 600;
}

.socialFollowIcons {
  display: flex;

  gap: 50px;
  padding: 2rem 0;
}

.socialFollowIcons a {
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  padding: 8px 10px 8px 10px;
  color: black;
  transition: transform 0.3s;
}

#FollowIcons {
 
  font-size: 1.7rem;
}


.socialFollowIcons a:hover {
  transform: translateY(-5px);
  background-color: rgb(0, 0, 0);
  color: white;
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333;
}

@media (max-width: 770px) {
  .facebookReviewsSection #heading1 {
    font-size: 45px;
  }

  #heading2 {
    font-size: 45px;
  }

  .followUsLinks h4 {
    font-size: 2.8em;
  }
}
@media (max-width: 720px) {
  .followUsLinks {
    flex-direction: column;
  }

  .followUsLinks hr {
    height: 0px;
    width: 300px;
  }
  .socialFollowIcons {
    justify-content: center;
  }
}
@media (max-width: 460px) {
  .socialFollowIcons {
    gap: 20px;
    justify-content: center;
  }
}
