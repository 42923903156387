* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.shopWrapper {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  font-family: "Roboto", Sans-serif;
}
.shopWrapper h2 {
  font-size: 2.5rem;
  font-weight: 500;
}
.shopGridView {
  display: flex;
  gap: 30px;
}
@media (max-width: 750px) {
  .shopGridView {
    display: block;
  }
}
/* ////////////////products//////////////////////////////// */
.productView {
  width: 100%;
}
.gridViewSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 5 columns */
      grid-auto-rows: minmax(100px, auto); 
  gap: 20px; /* Gap between grid items */
  margin-top: 20px;
}
.gridViewSection ul {
  padding: 0px;
  margin: 0px;
}
.gridViewSection ul p {
  padding: 0px;
  margin: 0px;
}
.productCard {
  width: 100%; /* Take full width of each grid cell */
}
.ratingAndPrice {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}
.productCard img {
  width: 100%;
  height: auto;
}
.productCard {
  padding: 5px;
  background-color: #fff;
  border: 1px solid rgba(167, 166, 166, 0.737);
  border-radius: 4px;
}
.productDetails h3 {
  font-size: 15px !important;
  color: #fff;
  text-align: center;
  background-color: black;
  width: 100%;
  padding: 20px;
  margin: 10px 0px;
}
.cardDetails li {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  margin-bottom: 10px;
}
.rating {
  margin-bottom: 10px;
}

.rating span {
  color: #ed2025;
  font-size: 1.2rem;
  font-weight: 900;
}

.filled {
  color: #ed2025;
}
.postcodeList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 12px; /* Gap between grid items */
  padding-left: 0px;
}
.postcodeList li {
  padding: 9px;
  background-color: #eb0009;
  color: #fff;
  text-align: center;
  border: 1px solid #2d2d2d;
}

.price {
  font-size: 22px !important;
  color: #ed2025 !important;
  font-weight: 600 !important;
}
.cardDetails p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #111111;
}
.postCodeBlock p {
  font-size: 1.2rem;
  font-weight: 600;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bookNow,
.more {
  padding: 10px 20px;
  background-color: #111111;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bookNow:hover,
.more:hover {
  background-color: #eb0009;
}
/*//////////////////////////////// Product details page //////////////////////// */
.ProductDetailsPage {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  font-family: "Roboto", Sans-serif;
}
.productDetailsPageSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.productDetailsPageImage img {
  width: 100%;
  height: auto;
}
.productDetailsPageImage {
  max-width: 50%;
  width: 100%;
  padding: 0px 2rem;
}
.productDetailsPageDetails {
  max-width: 50%;
  width: 100%;
  padding: 0px 2rem;
}
.productDetailsPagetNamePrice h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  margin-bottom: 15px;
}
.productDetailsPagetNamePrice p {
  color: #ed2025;
  font-size: 1.8em;
  margin-bottom: 0px;
}
.productDetailsPageCard ul {
  padding-left: 0px;
}
.productDetailsPageCard ul li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  background: #eee;
  padding: 10px;
}
.productDetailsPageCard ul li p {
  margin-bottom: 0px;
  color: black;
}
#productmenuArrowIcon {
  font-weight: 900;
}
.areaIncludedBlock p {
  font-size: 1.2rem;
  font-weight: 600;
}
.areaIncludedList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 12px; /* Gap between grid items */
  padding-left: 0px;
}
.areaIncludedList li {
  padding: 10px;
  background-color: #eb0009;
  color: #fff;
  text-align: center;
  border: 1px solid #2d2d2d;
}
.productBookButton {
  display: flex;
  align-items: center;
}
.quantityControl {
  margin-right: 1rem;
}
.quantityControl button {
  padding: 2px 1rem;
  border: none;
}
.quantityControl span {
  padding: 1px 2rem;
  color: #111;
}
.productDetailsCategoryDiv p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.productDetailsCategoryDiv span {
  color: #eb0009;
  font-size: 1rem;
  cursor: pointer;
}
.productDetailsDiscriptionSection {
  padding: 2rem 0px;
}
.productDetailsContainer {
  max-width: 1000px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: "Montserrat", sans-serif;
}

#thIdDiscription {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 10px 1rem;
}
.productDetailsContent {
  width: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 2rem 1.5rem;
}
.productDetailsContent h2 {
  color: #eb0009;
}
.productDetailsContent hr {
  border: 1px solid #eb0009;
  opacity: 1;
}

/* ///////////////////// media quary of product details ////////////////// */

@media (max-width: 770px) {
  .productDetailsPageSection {
    display: block;
  }
  .productDetailsPageImage {
    max-width: 100%;
    width: 100%;
  }
  .productDetailsPageDetails {
    max-width: 100%;
    width: 100%;
    margin-top: 1rem;
  }
}
@media (max-width: 394px) {
  .productDetailsPageCard ul li {
    display: block;
  }
  .quantityControl {
    margin-right: 1rem;
  }
  .quantityControl span {
    padding: 1px 1rem;
  }
}
@media (max-width: 344px) {
  .quantityControl {
    margin-right: 0.5rem;
  }
  .quantityControl span {
    padding: 1px 0.5rem;
  }
}
@media (max-width: 324px) {
  .productBookButton {
    display: block;
  }
}
/* ////////////////////////////// */

/* ////////////////////////////////////////////////////////////// */
.productDetailsProductTab {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.productTab {
  padding: 2rem 0px;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
/* ///////////////////////////////////// */
.TSvideosContanierSection {
  background-image: url(../../assets/images/video-bgImg.jpg);
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
}
.theorySupportContentVideo iframe {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 1;
  border: none;
}
.theorySupportContentVideosec {
  padding: 5rem 1rem;
  max-width: 800px;
  width: 100%;
  margin-right: auto;
  font-family: "Roboto", sans-serif;
  margin-left: auto;
}
.theorySupportContentVideosec video {
  width: 100%;
}

/* ///////////////////////////////// */

.whyChooseshortSection {
  max-width: 1140px;
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 0px;
  font-family: "Roboto", sans-serif;
}

.whyChooseshortSectionContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.whyChooseshortSectionImage {
  width: 100%;
}
.whyChooseshortSectionImage img {
  border-radius: 10px;

  width: 100%;
}
.whyChooseshortSectionText {
  padding: 1rem 0px;
  width: 100%;
}

.whyChooseshortSection h2 {
  font-size: 28px;
  margin-bottom: 25px;
  font-family: "Montserrat", sans-serif !important;
}
.whyChooseshortSection h2 span {
  color: #ed2025;
}

.whyChooseshortSection p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif !important;
}
.whyChooseshortSection p a {
  color: #ed2025;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}
.whyChooseshortSectionButtons {
  display: flex;
  gap: 10px;
}

.whyChooseshortSection button {
  background: #111;
  color: #fff;
  padding: 15px;

  text-align: center;

  font-size: 18px;
  border-radius: 5px;
  border: none;
}

.whyChooseshortSection button:hover {
  background-color: #ed2025;
  transition: 0.3s ease-in-out;
}
/*  */
@media (max-width: 890px) {
  .whyChooseshortSectionContent {
    display: block;
    text-align: center;
    padding: 0px 2rem;
  }
  .whyChooseshortSectionButtons {
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .whyChooseshortSectionContent {
    display: block;
    text-align: center;
    padding: 0px 1rem;
  }
  .whyChooseshortSectionButtons {
    display: flex;
    flex-direction: column;
  }
  .whyChooseshortSection h2 {
    font-size: 22px;
  }
}

/* //////////////////////////////////////// */

.nextFormSection {
  background: url(https://smartlearner.com/wp-content/themes/smartlearner/assets/images/call-out-bg.jpg)
    no-repeat center top / cover;
  padding: 70px 0;
}
.nextFormContainer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.nextFormDetailsContainer {
  width: 100%;
}
.nextFormDetailsContainerHeading {
  display: flex;
  align-items: center;
}
.nextFormDetailsContainer h3 {
  background-color: #f7b500;
  padding: 15px;
  color: #fff;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif !important;
  margin-bottom: 0px;
}
.nextFormDetailsContainer span {
  background: #ed2025;
  padding: 15px;
  color: white;
}
.nextFormDetailsContainer span #rightArow {
  font-weight: 900;
  font-size: 1.8rem;
}
.nextFormDetailsContainer ul {
  background: rgba(0, 0, 0, 0.9);
  padding: 25px;
  color: #fff;
  margin-top: 30px;
}
.nextFormDetailsContainer ul li {
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 5px;
}
.nextFormDetailsContainer ul li #redStar {
  color: #ed2025;
}
@media (max-width: 700px) {
  .nextFormContainer {
    display: block;
  }
}

/* ///////////////////////////////////// */
.drivenBefore {
  padding: 2rem 0px;
}
.drivenBefore h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 0px;
}
/* ////////////////////Product card */
.chooseProductSection {
  max-width: 1140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.gridViewSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 5 columns */
 
  gap: 10px; /* Gap between grid items */
  margin-top: 20px;
}
@media (max-width: 1152px) {
  .gridViewSection{
    grid-template-columns: repeat(2, 1fr); 
  } 
  .gridViewSection .productCard .quantityControl span{
    
    padding: 1px 1rem;
  }
  .gridViewSection .productCard .quantityControl button {
    padding: 2px 0.5rem;
    border: none;
  }
}
@media (max-width: 510px) {
  .gridViewSection{
    grid-template-columns: repeat(1, 1fr); 
  } 
}
.gridViewSection ul {
  padding: 0px;
  margin: 0px;
}
.gridViewSection ul p {
  padding: 0px;
  margin: 0px;
}
/* .productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px; 
  margin-top: 20px;
} */
.productCard {
  width: 100%; /* Take full width of each grid cell */
}
.ratingAndPrice {
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}
.productCard img {
  width: 100%;
  height: auto;
}
.productCard {
  padding: 5px;
  border: 1px solid rgba(167, 166, 166, 0.737);
  border-radius: 4px;
  background-color: #fff;
}
.productDetails h3 {
  font-size: 15px !important;
  color: #fff;
  text-align: center;
  background-color: black;
  width: 100%;
  padding: 20px;
  margin: 10px 0px;
}
.cardDetails li {
  display: flex;
  justify-content: space-between;
  padding-left: 0px;
  margin-bottom: 10px;
}
.rating {
  margin-bottom: 10px;
}

.rating span {
  color: #ed2025;
  font-size: 1.2rem;
  font-weight: 900;
}

.filled {
  color: #ed2025;
}
.postcodeList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  gap: 12px; /* Gap between grid items */
  padding-left: 0px;
}
.postcodeList li {
  padding: 9px;
  background-color: #eb0009;
  color: #fff;
  text-align: center;
  border: 1px solid #2d2d2d;
}

.price {
  font-size: 22px !important;
  color: #ed2025 !important;
  font-weight: 600 !important;
}
.cardDetails p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #111111;
}
.postCodeBlock p {
  font-size: 1.2rem;
  font-weight: 600;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bookNow,
.more {
  padding: 10px 20px;
  background-color: #111111;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.bookNow:hover,
.more:hover {
  background-color: #eb0009;
}
/*//////////////////////////////// Product details page //////////////////////// */
.ProductDetailsPage {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1rem;
  font-family: "Roboto", Sans-serif;
}
.productDetailsPageSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.productDetailsPageImage img {
  width: 100%;
  height: auto;
}
.productDetailsPageImage {
  max-width: 50%;
  width: 100%;
  padding: 0px 2rem;
}
.productDetailsPageDetails {
  max-width: 50%;
  width: 100%;
  padding: 0px 2rem;
}
.productDetailsPagetNamePrice h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
  margin-bottom: 15px;
}
.productDetailsPagetNamePrice p {
  color: #ed2025;
  font-size: 1.8em;
  margin-bottom: 0px;
}
.productDetailsPageCard ul {
  padding-left: 0px;
}
.productDetailsPageCard ul li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  background: #eee;
  padding: 10px;
}
.productDetailsPageCard ul li p {
  margin-bottom: 0px;
}
#productmenuArrowIcon {
  font-weight: 900;
}
.areaIncludedBlock p {
  font-size: 1.2rem;
  font-weight: 600;
}
.areaIncludedList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 12px; /* Gap between grid items */
  padding-left: 0px;
}
.areaIncludedList li {
  padding: 10px;
  background-color: #eb0009;
  color: #fff;
  text-align: center;
  border: 1px solid #2d2d2d;
}
.productBookButton {
  display: flex;
  align-items: center;
}
.quantityControl {
  margin-right: 1rem;
}
.quantityControl button {
  padding: 2px 1rem;
  border: none;
}
.productDetailsPageSection .quantityControl span {
  padding: 1px 2rem;
  color: white;
}
/* ///////////////////// media quary of product details ////////////////// */

@media (max-width: 770px) {
  .productDetailsPageSection {
    display: block;
  }
  .productDetailsPageImage {
    max-width: 100%;
    width: 100%;
  }
  .productDetailsPageDetails {
    max-width: 100%;
    width: 100%;
    margin-top: 1rem;
  }
}
@media (max-width: 394px) {
  .productDetailsPageCard ul li {
    display: block;
  }
  .quantityControl {
    margin-right: 1rem;
  }
  .quantityControl span {
    padding: 1px 1rem;
  }
}
@media (max-width: 344px) {
  .quantityControl {
    margin-right: 0.5rem;
  }
  .quantityControl span {
    padding: 1px 0.5rem;
  }
}
@media (max-width: 324px) {
  .productBookButton {
    display: block;
  }
}
/* //////////////////////////////////////// */
/* ////////////////////////////////////// */
/* //////////////////////////////////////// */
/* ////////////////////////////////////// */
/* ////////////////////////////////////////// */
.callbackFormSection {
  padding: 2rem 0px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}
.callbackFormContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px;

  box-shadow: 0 0 10px #ccc;
}
.callbackFormContentPG {
  width: 100%;
  padding: 50px 30px;
  box-shadow: 0 0 10px #ccc;
}
.callbackFormContentPG p {
  text-align: center;
}
@media (max-width: 728px) {
  .callbackFormContent {
    display: block;
    padding: 15px;
  }
  .callbackFormContentPG {
    margin-bottom: 1rem;
  }
  .callbackFormContentPG {
    padding: 30px 15px;
  }
}
@media (max-width: 528px) {
  .callbackFormContent {
    padding: 15px;
  }

  .callbackFormContentPG {
    padding: 30px 15px;
  }
}
/* //////////////////////////////////////////////// */
.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  list-style: none;
  padding: 0;
  color: #ed2025;
}

.pagination li {
  margin: 0 5px;
  background-color: #111;
}

.pagination a {
  color: white;
  padding: 10px 15px;
  border: 1px solid white;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.pagination  .active a{
  background-color: white;

 
  
}

.pagination .disabled a {
  color: grey;
  cursor: not-allowed;
}

.pagination a:hover {
  background-color: #555;
  border-color: #555;
}