* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.OurOfficeGreenEfforts {
  background-color: black;
  padding-bottom: 6rem;
  padding-top: 1rem;
  font-family: "Antonio", sans-serif;
}
.csrPageFront {
  background: url("../../assets/images/officeEffortsBanner.jpg");
  color: white;
  background-size: cover;
  background-position: top;
  border-radius: 10px;
  padding: 10rem 1rem;
  position: relative;
}

.eCSfrontSection {
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
  color: white;
  position: sticky;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
}
.eCSfrontSection h2 {
  font-size: 5rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 3rem;
  font-weight: 700;
  color: white;
}

.csrPagesLd {
  padding: 1rem;
  position: sticky;
  max-width: 1000px;
  margin-left: auto;
  margin-right: 44%;
  width: 100%;
  background: linear-gradient(135deg, #00157199, #01764b76);
  border-radius: 6px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.656);
}
.csrPagesLd p {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 0px;
  text-align: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ourSecondryDiv {
  padding: 2rem 1rem;
  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.headinSection {
  text-align: center;
}
.headinSection h2 {
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: white;
}
.headinSection img {
  max-width: 300px;
  width: 100%;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(208, 208, 208, 0.757));
}
.ourSecondryPSec {
  padding: 2rem 0px;
}
.ourSecondryPSec p {
  background: linear-gradient(135deg, #00157199, #01764b76);
  border-radius: 10px;
  max-width: 900px;
  width: 100%;
  color: white;
  margin-right: auto;
  margin-left: auto;
padding: 1rem;
  font-size: 1.2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media (max-width: 780px) {
  .eCSfrontSection h2 {
    font-size: 6rem;
  }
  .headinSection h2 {
    font-size: 3rem;
  }

  .ourSecondryPSec p {
    font-size: 1rem;
  }
}

@media (max-width: 450px) {
  .eCSfrontSection h2 {
    font-size: 4.5rem;
  }
  .headinSection h2 {
    font-size: 2.5rem;
  }
}
.eCsfront {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}
.ourOPresEfforts hr {
  opacity: 1;
  border: 2px solid rgb(0, 251, 222);
  margin: 0px;
}
.ourOPresEffortsHead {
  padding: 2rem 1rem;
  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.ourOPresEffortsHead h2 {
  color: rgba(0, 249, 203, 0.93);
  font-size: 3rem;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}
.ourOPresList ul {
  padding: 1rem 1rem;
}
.ourOPresList ul li {
  display: flex;
  background: linear-gradient(135deg, #00bbaf99, #4b017676);
  align-items: center;
  max-width: 800px;
 
  padding: 0.5rem;
  border-radius: 10px;
  width: 100%;
  gap: 40px;
  margin: 1rem auto;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.ourOPresList ul li span {
  max-width: 80px;
  width: 100%;
}
.ourOPresList ul li span img {
  max-width: 50px;
  width: 100%;
}
.ourOPresList ul li p {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 0px;
}
@media (max-width: 780px) {
  .ourOPresEffortsHead h2 {
    font-size: 2.5rem;
  }
  .ourOPresList ul li p {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .ourOPresList ul li span {
    max-width: 60px;
    width: 100%;
  }
  .ourOPresList ul li span img {
    max-width: 50px;
    width: 100%;
  }
}
