* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.goingGreenProject {
  background-color: black;
  padding-bottom: 7rem;
  padding-top: 1rem;
  
  font-family: "Antonio", sans-serif;
}

.csrPageFront {
  background: url("../../assets/images/goingGreenBanner.jpg");
  color: rgb(255, 255, 255);
  background-size: cover;
  border-radius: 10px;  
  padding: 8rem 1rem;
  position: relative;
}

.gGPFrontSection {
position: sticky;
  padding: 2rem 1rem;
max-width: 1640px;
margin-right: auto;
margin-left: auto;
}
.gGPFrontSection h2 {
  font-size: 5rem;
  margin-bottom: 3rem;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  position: sticky;
 
}



.gGpFrontSectionD {
  display: flex;
  align-items: center;
  gap: 50px;
}
.gGpFrontImgS img {
  max-width: 300px;
  width: 100%;
}
.gGpFrontListP p {
  color: white;
  background: linear-gradient(135deg, #00157199, #01764b76);
  font-size: 1.2rem;
  padding: 1rem;
  position: sticky;
  max-width: 1000px;
  margin-left: auto;
  margin-right: 44%;
  width: 100%;
 
  border-radius: 6px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 0 20px rgba(54, 54, 54, 0.656);

  margin-bottom: 1rem;

}
/* ///////////////////////////// */
.mainGoingGreenProject {
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
}
.secondrySection {
  padding: 2rem 0px;
 
}
.secondryHeading h2 {
  text-align: center;
  font-size: 4rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: white;
}
.secondryHeading hr {
  border: 2px solid white;
  opacity: 1;
}

.secondrySection img {
  max-width: 500px;
  aspect-ratio: 1/1;
  /* object-fit: contain; */
  width: 100%;
  box-shadow: 0 0 20px rgb(95, 95, 95);
  border-radius: 10px;
}
.secondrySection img:hover{
  transform: scale(1.05);
  transition: ease-in-out 0.2s;
} 

@media (max-width: 728px) {
  .gGPFrontSection h2 {
    font-size: 5rem;
  }
  .gGpFrontListP p {
    font-size: 1rem;
  }
  .secondryHeading h2 {
    font-size: 4rem;
  }
  .secondryImgSection {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 3rem 1rem;
  }
}
@media (max-width: 567px) {
  .gGpFrontSectionD {
    flex-direction: column;
  }
  .gGpFrontImgS img {
    max-width: 250px;
    width: 100%;
  }
  .gGPFrontSection h2 {
    font-size: 4rem;
  }
  .secondryHeading h2 {
    font-size: 3.5rem;
  }
}
@media (max-width: 428px) {
  .gGPFrontSection h2 {
    font-size: 3rem;
  }

  .secondryHeading h2 {
    font-size: 3rem;
  }
}
.prosConsSection {
  padding: 2rem 0px;
}

.ourProsConsdiv {
  display: flex;
  gap: 30px;
  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.ourProsConsdiv hr {
  border: 2px solid rgb(3, 154, 3);
  opacity: 1;
}
.ourProsConsSection {
  padding: 2rem 1rem;
  background: linear-gradient(135deg, #00157199, #01764b76);
  border-radius: 10px;
}
.ourProsConsSection h2 {
  font-size: 3rem;
  color: white;
  text-align: center;
  margin-bottom: 1.7rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.ourProsConsSection p {
  color: white;
  margin-bottom: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
}
.chartBarImgSec {
  text-align: center;
  padding: 2rem 1rem;
}

.chartBarImgSec h2 {
  font-size: 5rem;
  text-align: center;
  color: rgb(0, 130, 149);
}

.chartContainer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 300px;

  padding: 20px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bar {
  width: 50px;
  background-color: rgb(3, 154, 121);
  transform: scaleY(0);
  transform-origin: bottom;
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@media (max-width: 750px) {
  .chartBarImgSec h2 {
    font-size: 4.5rem;
  }
}
@media (max-width: 610px) {
  .ourProsConsdiv {
    flex-direction: column;
  }
  .chartBarImgSec h2 {
    font-size: 4rem;
  }
}
@media (max-width: 450px) {
  .chartBarImgSec h2 {
    font-size: 3rem;
  }
}
.treePlantationSection {
  padding-bottom: 2rem;
}
.treePlantationDiv h2 {
  font-size: 2.2rem;
  text-align: center;
  color: rgb(255, 255, 255);
  max-width: 750px;
  padding: 0.8rem;
  margin: 0px auto;
  border-radius: 10px;
  background: linear-gradient(135deg, #00157199, #01764b76);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.treeIconDiv {
  text-align: center;
  padding: 2rem 1rem;
}
.treeIconDiv img {
  max-width: 500px;
  width: 100%;
}
.treeIconImgSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.headingtreeNo h2 {
  font-size: 7rem;
  text-align: center;
  margin-bottom: 8rem;
  color: rgb(4, 221, 177);
}
.plantImgSection img {
  max-width: 400px;
  width: 100%;
}
#handPlant {
  margin-bottom: 12rem;
}
#handPlant1 {
  animation: 4s slide-right;

}

@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
#flowerPlant {
  margin-top: 10rem;
}
.flowerPlant {
  position: relative;
  animation: 4s slide-left;
 
}
@keyframes slide-left {
  from {
    right: -100%;
  }
  to {
  right: 0%;
  }
}
/* ///////////////////////////////// */
.ggOlSection ul {
  max-width: 700px;
  background: linear-gradient(135deg, #40007199, #01764b76);
  border-radius: 10px;
  padding: 2rem;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10rem;
 
}
.ggOlSection ul li {
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0px 1rem;
  margin-bottom: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.ggOlSection ul li span {
  font-size: 2rem;
  max-width: 50px;
  width: 100%;
  color: rgb(0, 230, 169);
}
.ggOlSection ul li p {
  color: white;
  font-size: 1.7rem;
  margin-bottom: 0px;
  
}
@media (max-width: 728px) {
  .treePlantationDiv h2 {
    font-size: 2.5rem;
  }
  .treeIconImgSection {
    flex-direction: column;
    gap: 15px;
  }
  .headingtreeNo h2 {
    margin-bottom: 0px;
  }
  #handPlant {
    margin-bottom: 0px;
  }
  #flowerPlant {
    margin-top: 0px;
  }

  .ggOlSection ul {
    max-width: 500px;
    width: 100%;
    margin-top: 0px;
  }
  .ggOlSection ul li span {
    font-size: 2rem;
  }
  .ggOlSection ul li p {
    font-size: 1.2rem;
  }
}
@media (max-width: 580px) {
  .treePlantationDiv h2 {
    font-size: 2rem;
  }
  .headingtreeNo h2 {
    font-size: 5rem;
  }
  .ggOlSection ul li span {
    font-size: 1.5rem;
    max-width: 30px;
    width: 100%;
  }
  .ggOlSection ul li p {
    font-size: 1rem;
  }
}
/* 
//////////////////////////////////////// */
/* ////////////////////////////////////// */

.callUsSectiongg hr {
  border: 2px solid rgb(167, 3, 3);
  opacity: 1;
}
.getInvolved {
  padding: 2rem 0px;
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.getInvolved h2 {
  font-size: 4rem;
  color: rgb(3, 154, 43);
  margin-bottom: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.getInvolved p {
  color: white;
  font-size: 1.2rem;
}
.drivingLessonsCd {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 1rem 0px;
}
.drivingLessonsCd section a{
  display: flex;
  gap: 30px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #00157199, #01764b76);
  padding: 5px 1rem;
}
.drivingLessonsCd section p {
  margin: 0px;
  color: white;
  font-size: 1.5rem;
  font-family: "Antonio", sans-serif;
}
#IconsS {
  font-size: 1.8rem;

  color: white;
}
/* //////////////////////// */

.socialFollowIcons {
  display: flex;
  justify-content: space-evenly;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: 0.5rem 0;
  border-radius: 10px;
  background: linear-gradient(135deg, #00157199, #01764b76);
}

.socialFollowIcons a {
  display: inline-block;
  background-color: white;
  border-radius: 6px;
  padding: 8px 10px 8px 10px;

  transition: transform 0.3s;
}

#FollowIcons {
  color: rgb(3, 154, 3);
  font-size: 1.7rem;
}

.socialFollowIcons a:hover {
  transform: translateY(-5px);
}

.socialFollowIcons i {
  font-size: 24px;
  color: #333;
}
@media (max-width: 680px) {
  .getInvolved h2 {
    font-size: 3.5rem;
  }
}
@media (max-width: 480px) {
  .getInvolved h2 {
    font-size: 3rem;
  }
}
