* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.imageSection {
  position: relative;
  width: 100%;
  padding: 5rem 0;
  background-color: #ff0000;
}
.maincontent {
  display: flex;
  max-width: 1300px;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.content {
  position: relative;
  z-index: 1;

  width: 100%;
  color: white;
  text-align: center;
  padding: 0px 20px 0px 0px;
}

.heading1 h1 {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-family: "Montserrat", sans-serif;
}
.heading2 h2 {
  color: #ffffff;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2em;
  font-family: "Montserrat", sans-serif;
  padding-top: 0.8rem;

  margin-bottom: 20px;
}
#btn {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 15px 60px 15px 60px;
  transition: color 0.3s, border-color 0.3s, transform 0.3s;
}

#btn:hover {
  background-color: #500202;
  transform: scale(1.1);
  animation: bounce 0.3s infinite alternate;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
.video {
  width: 100%;
  text-align: center;
}

.video img {
  width: 100%;
  max-width: 400px;
}
@media (max-width: 1020px) {
  .content {
    padding-top: 75px;
  }
  .heading1 h1 {
    font-size: 0.9em;
    line-height: 1.7em;
  }
  .heading2 h2 {
    font-size: 27px;
  }
  .video {
    padding: 75px;
  }
}
@media (max-width: 767px) {
  .content {
    padding-top: 0px;
  }

  .maincontent {
    display: block;
  }
  .heading1 {
    padding-top: 1rem;
  }
  .content {
    max-width: 100%;
  }
  .video {
    max-width: 100%;
  }
}
@media (max-width: 350px) {
  .video {
    padding: 40px;
  }
}
/* //////////////////////////////////////////////////////////// */
/* /////////////////////////////Our beloved pupils ////////////////// */
.belovedCustomerSection {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  text-align: center;
  font-family: "Roboto", Sans-serif;
  max-width: 1600px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.belovedCustomerdetails {
  width: 100%;
}
.belovedCustomerdetails h1 {
  color: #ff0000;
  font-size: 55px;
  font-weight: 800;
  line-height: 1.2em;
  margin-bottom: 20px;
}

.belovedCustomerdetails p {
  line-height: 28px;
  text-align: center;
  color: #000000;
  font-family: "Roboto", Sans-serif;
  font-size: 1.2em;
  font-weight: 400;
}
.belovedCustomerdetails p a {
  text-decoration: none;
  color: #eb0009;
}
.belovedCustomerdetails hr {
  margin-right: auto;
  margin-left: auto;
  border: 2px solid black;
  opacity: 1;
  max-width: 600px;
}
.belovedCustomerdetailsQuestionaries{
    padding: 1rem 0px;
}
.pupilsImg {
  width: 100%;
  padding: 10px;
}
.pupilsImg img {
  width: 100%;
  max-width: 700px;
}
@media (max-width: 1020px) {
  .belovedCustomerdetails h1 {
    font-size: 32px;
  }
  .belovedCustomerdetails p {
    font-size: 15px;
  }
  .belovedCustomerdetails {
    width: 60%;
  }
  .pupilsImg {
    width: 40%;
  }
}
@media (max-width: 777px) {
  .belovedCustomerSection {
    display: block;
  }
  .belovedCustomerdetails {
    width: 100%;
    margin: 0;
  }
  .pupilsImg {
    width: 100%;
  }
  .belovedCustomerdetails h1 {
    font-size: 45px;
  }
  .belovedCustomerdetails p {
    font-size: 1.2em;
    line-height: 22px;
    padding: 0px 20px 0px 20px;
  }
}
@media (max-width: 468px) {
  .belovedCustomerdetails h1 {
    font-size: 25px;
  }
  .belovedCustomerdetails p {
    font-size: 15px;
    padding: 0px 20px 0px 20px;
  }
}

 /*//////////////////////////// Smartlearner stats /////////////////////////////// */
 .smartleanerStats{
       
    background-color: #eb0009;
  }
  .smartleanerStatsSection{
    padding: 4rem 1rem;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }
  .smartleanerStatsSection h3{
    font-size: 65px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 2rem;
  }
  .smartleanerStatsSection P{
    color: #FFF;
    margin-bottom: 1rem;
  }
    .statsContaier{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .numberContainer{
        padding: 55px;
      
        background-color: #181616;
        
        width: 100%;
    }
    .numberContainer span{
        color: #F60202;
        font-size: 105px;
        font-weight: 500;
    }
   .numberContainer p{
    color: #fff;
   }
    #statslowerheading{
        color: #ffffff;
        font-size: 15px;
        font-weight: 600;
        line-height: 2.5;
    }
    @media (max-width: 728px){
        .statsContaier{
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        }
        .smartleanerStatsSection h3{
            font-size: 35px;
        }
        .numberContainer{
            padding: 30px;
        }
        .numberContainer span{
            font-size: 45px;
        }
    }
    /* ///////////////////////////////////////////////// */
    /* /////////////////////Included///////////////////////// */

  .features {
    padding: 3rem 0;
  }
  .mainFeatures {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    font-family: "Roboto", sans-serif;
    text-align: center;
    max-width: 1400px;
   padding: 0rem 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .column {
    padding: 25px 25px 55px 25px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #eb0009;
    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
    border: none;
    transition: box-shadow 0.3s ease;
  }
  
  .column:hover {
    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.203);
    border: 1px solid #eb0009;
  }
  .column span {
    font-size: 50px;
    color: #706c6c;
    display: inline-block;
    padding: 30px;
    margin-right: auto;
    margin-left: auto;
  }
  .column h3 {
    font-size: 26px;
    line-height: 1.2;
    color: #eb0009;
    margin-bottom: 10px;
    margin-top: 1rem;
  }
  .column p {
    margin: 1rem;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    line-height: 1.7em;
    color: #7a7a7a;
  }
  .column span{
    background-color: #eb0009;
    padding: 30px;
border-radius: 50%;
font-size: 50px;
color: #FFF;
  }
  #featuresIcon {
    width: 1em;
    height: 1em;
    position: relative;
    display: block;
    
  }
  .column span:hover {
    transform: scale(1.2);
    background-color: #fff;
    color: #eb0009;
  }
/* ////////////////////////////// tab section ////////////////////// */
.tabsection {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0px 1rem;
    font-family: "Roboto", Sans-serif;
  }
  
  .tabs {
    display: flex;
   
  }
  
  .tab {
    padding: 20px 25px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    border-bottom: none;
  }
  
  .tab.active {
    background-color: #eb0009;
    color: white;
  }
  
  .tabContent {
    padding: 20px;
    border-top: none;
    background-color: #eb0009;
  }
  
  .tabContent p {
    text-align: left;
    font-size: 1rem;
    color: #fff;
    line-height: 28px;
  }
  
  @media screen and (max-width: 768px) {
    .tabs {
      flex-direction: column; 
    }
  
    .tab {
      width: 100%; 
      border-bottom: 1px solid #ddd;
    }
  }
  