* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.homecontent-Sec {
  background: url("../../../assets/images/Untitled\ design\ \(7\).png");
  background-size: cover;
  position: relative;
  padding: 4rem 0px;
}
.opicity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000000d0;
}

.home-banner-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.home-content-D {
  position: relative;
  padding: 4rem 1rem;
  max-width: 62%;

  width: 100%;

  text-align: left;
}
.home-content-D h1 {
  font-size: 4.5rem;
  padding-bottom: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;

  margin: 0px auto;

  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.home-content-D p {
  padding: 2rem 1rem;
  font-size: 1.3rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  border-radius: 10px;
  color: rgb(255, 255, 255);
  background: linear-gradient(135deg, #ca45ff5d, #00024038);
}
#home-bannerbnt-sec {
  display: flex;
  gap: 50px;
}
.home-bannerbnt-sec span a {
  background: linear-gradient(
    135deg,
    #ff6347,
    #ff1493
  ); /* Apply the gradient */
  -webkit-background-clip: text; /* Clip the background to the text */
  background-clip: text; /* For non-webkit browsers */
  color: transparent;
  font-size: 2rem;
}

.home-bannerbnt-sec span a:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.2s;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(255, 255, 255, 0.8));
}
.gradient-icon {
  color: #ff6347; /* Apply the gradient */
}

.button-style {
  background: linear-gradient(135deg, #ff6347, #ff1493);
  border: none;
  padding: 10px 30px;
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  border-radius: 6px;
  cursor: pointer;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  transition: all 0.3s ease;
  outline: none;
}
.button-style:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.2s;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}
.banner-img {
  max-width: 48%;
  width: 100%;
  height: 400px;
  position: relative;
}
.banner-img p {
  max-width: 170px;
  height: 170px;
  color: rgb(212, 224, 255);
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  box-shadow: 0 0 20px rgba(206, 235, 255, 0.852);
  width: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
#home-drivingImg-1 {
  position: absolute;
  top: -50px;
  right: 200px;
  background: linear-gradient(135deg, #00ffe1, #2600fd);
}
#home-drivingImg-2 {
  position: absolute;
  top: -150px;
  right: 500px;
  background: linear-gradient(135deg, #ffac47, #fa4700);
}
#home-drivingImg-3 {
  position: absolute;
  top: 180px;
  right: 450px;
  background: linear-gradient(135deg, #dd00f1, #00aaff);
}

@keyframes smoothUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Move the images up */
  }
  100% {
    transform: translateY(0); /* Move the images back to original position */
  }
}
#home-drivingImg-1:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 255, 255);
  cursor: pointer;
}
#home-drivingImg-2:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 255, 255);
  cursor: pointer;
}
#home-drivingImg-3:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px rgb(255, 255, 255);
  cursor: pointer;
}

#home-drivingImg-1,
#home-drivingImg-2,
#home-drivingImg-3 {
  animation: smoothUpDown 2s ease-in-out infinite; /* Animation applied to both images */
  /* Optional, to add some space between the images */
}

/* If you want to delay the second image */
#home-drivingImg-2 {
  animation-delay: 1s; /* Delay the second image's animation for staggered effect */
}
#home-drivingImg-3 {
  animation-delay: 2s;
}
@media (max-width: 2100px) {
  .home-content-D h1 {
    font-size: 5.5rem;
  }
}
@media (max-width: 1900px) {
  .home-content-D h1 {
    font-size: 4.7rem;
  }
}
@media (max-width: 1600px) {
  .home-content-D h1 {
    font-size: 4.3rem;
  }
}
@media (max-width: 1400px) {
  .home-content-D h1 {
    font-size: 4rem;
  }
  #home-drivingImg-1 {
    position: absolute;
    top: -50px;
    right: 150px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: -150px;
    right: 400px;
  }
  #home-drivingImg-3 {
    position: absolute;
    top: 180px;
    right: 350px;
  }
}
@media (max-width: 1300px) {
  #home-drivingImg-1 {
    position: absolute;
    top: -50px;
    right: 50px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: -150px;
    right: 300px;
  }
  #home-drivingImg-3 {
    position: absolute;
    top: 180px;
    right: 200px;
  }
}
@media (max-width: 930px) {
  .banner-img p {
    max-width: 150px;
    height: 150px;
    font-size: 1.1rem;
  }

  #home-drivingImg-1 {
    position: absolute;
    top: -50px;
    right: 10px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: -150px;
    right: 250px;
  }
  #home-drivingImg-3 {
    position: absolute;
    top: 180px;
    right: 50px;
  }
}

@media (max-width: 900px) {
  .home-content-D h1 {
    font-size: 4rem;
  }
  .home-content-D {
    max-width: 70%;
    width: 100%;
  }
  .banner-img {
    max-width: 30%;
    width: 100%;
  }
}
@media (max-width: 840px) {
  .home-content-D {
    max-width: 80%;
    width: 100%;
  }
  .banner-img {
    max-width: 20%;
    width: 100%;
  }
}
@media (max-width: 777px) {
  .home-banner-flex {
    flex-direction: column-reverse;
  }
  .home-content-D {
    max-width: 100%;
  }
  .banner-img {
    max-width: 100%;
    height: 50px;
  }
  .banner-img p {
    max-width: 115px;
    height: 115px;
    font-size: 1rem;
  }

  #home-drivingImg-1 {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: 20px;
    right: 200px;
  }
  #home-drivingImg-3 {
    position: absolute;
    top: 10px;
    right: 400px;
  }
  .homecontent-Sec {
    padding: 2rem 0px;
  }
}
@media (max-width: 500px) {
  #home-drivingImg-1 {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: 20px;
    right: 150px;
  }
  #home-drivingImg-3 {
    position: absolute;
    top: 10px;
    right: 300px;
  }
}
@media (max-width: 420px) {
  .banner-img p {
    max-width: 100px;
    height: 100px;
  }
  #home-drivingImg-1 {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  #home-drivingImg-2 {
    position: absolute;
    top: 20px;
    right: 120px;
  }
  #home-drivingImg-3 {
    position: absolute;
    top: 10px;
    right: 250px;
  }
}
@media (max-width: 467px) {
  .home-content-D h1 {
    font-size: 3.5rem;
  }
  .home-content-D p {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .button-style {
    padding: 8px 25px;
    font-size: 1.2rem;
  }
  .home-bannerbnt-sec span {
    font-size: 1.5rem;
  }
}
@media (max-width: 410px) {
  .home-content-D h1 {
    font-size: 2.2rem;
  }
  .banner-img p {
    font-size: 14px;
    font-weight: 500;
  }
}

.designMainContent {
  max-width: 1640px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 4rem 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.dGrid-content {
  background-size: 1120px;
  background-image: url("./road.png");
  display: grid;

  grid-template-columns: repeat(1, 1fr);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 60% -45%;
  row-gap: 50px;
  width: 100%;
}
.flex-Content1 {
  display: flex;
}
.flex-Content2 {
  display: flex;
  flex-direction: row-reverse;
}
.flex-Content3 {
  display: flex;
}
.flex-Content4 {
  display: flex;
  flex-direction: row-reverse;
}
.single-map-block {
  display: flex;
  align-items: center;
  border-radius: 20px;
  max-width: 635px;
  width: 100%;
}
.single-map-block.card-1 {
  background: linear-gradient(135deg, #00ffe1, #2600fd);
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
}
.img-area {
  position: relative;
  height: 200px;
  width: 240px;
}
.img-area img {
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}
.img-area .number {
  position: absolute;
  top: -10px;
  left: 0;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: #3a3a3ac1;
  font-size: 35px;
  font-weight: 700;
  line-height: 50px;
  color: #fff;
  display: grid;
  place-items: center;
  z-index: 1;
}
.img-area img {
  max-width: 100%;

  object-fit: contain;
  height: auto;

  position: absolute;
  left: 0;

  display: block;
}
.content-area {
  padding: 20px 20px 20px 20px;
  max-width: 335px;
}
.content-area h4 {
  font-size: 2.1rem;
  font-weight: 700;
  text-align: left;
  color: rgb(194, 245, 255);
}
.content-area p {
  color: white;
  text-align: left;
}

.content-area a {
  margin-top: 14px;
  font-size: 21px;
  color: rgb(200, 24, 24);
  display: flex;
  column-gap: 5px;
  font-weight: 700;
  text-decoration: none;
  justify-content: center;
}
.content-area a:hover {
  color: rgb(81, 5, 5);
}

/* /////////////////////////////////////////////////// */
.single-map-block.card-4 {
  background: linear-gradient(135deg, #ffac47, #fa4700);
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
}
.single-map-block.card-2 .img-area .number {
  left: auto;
  right: 20px;
  top: -20px;
}

/* //////////////////////////////////////////// */
.single-map-block.card-3 {
  background: linear-gradient(135deg, #ffac47, #fa4700);
  box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
}
.single-map-block.card-3 .img-area img {
  border-radius: 50%;
  max-width: 190px;
}
/* /////////////////////////////////////////////// */
.single-map-block.card-4 {
  grid-area: card4;

  background: linear-gradient(135deg, #dd00f1, #00aaff);
  flex-direction: row-reverse;
}
.single-map-block.card-4 .img-area .number {
  left: auto;
  right: 20px;
  top: -20px;
}
.btn-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.btn-flex img {
  max-width: 100px;
  width: 100%;
}
.hBookNowBtn {
  background-color: rgb(200, 24, 24);
  color: white;
  padding: 7px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.hBookNowBtn:hover {
  background-color: rgb(81, 5, 5);
}

@media (max-width: 750px) {
  .dGrid-content {
    background-image: url("./mobileRoad.png");
    background-position: 60% -20%;
    background-size: 550px;
  }
  .single-map-block {
    max-width: 300px;
    width: 100%;
  }
  .single-map-block.card-1 {
    flex-direction: column;

    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
  }
  .single-map-block.card-2 {
    flex-direction: column;

    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
  }
  .single-map-block.card-3 {
    flex-direction: column;

    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
  }
  .single-map-block.card-4 {
    flex-direction: column;

    box-shadow: 0px 0px 55px 5px rgba(0, 0, 0, 0.06);
  }
}
@media (max-width: 428px) {
  .img-area {
    max-width: 250px;
  }
  .img-area img {
    max-width: 250px;
    margin-right: auto;
    margin-left: auto;
    position: static;
  }
}
