* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.goCv {
  background-color: black;
  font-family: "Antonio", sans-serif;
  color: white;
  padding-bottom: 5rem;
  padding-top: 1rem;
}
.csrPageFront {
  background: url("../../assets/images/partnerbanner2.jpg");
  color: rgb(255, 255, 255);
  background-size: cover;
  border-radius: 10px;  
  padding: 8rem 1rem;
  position: relative;
}

.goCvFirstSection {
 
 position: sticky;

}

.goCvFirstSectionHeading h2 {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 3rem 0px;
  
}



.goCvFirstSectionHeading hr {
  border: 2px solid rgb(34, 243, 34);
  opacity: 1;
  max-width: 600px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.goCvFirstSectionHeadingImg {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  padding: 3rem 0px;
  max-width: 1000px;
  width: 100%;

  margin-right: auto;
  margin-left: auto;
  position: relative;
}
#smartlearnerLogo {
  max-width: 350px;
  width: 100%;
}


.goCvFirstSectionHeadingImg img {
  max-width: 130px;
  width: 100%;
  padding: 1rem 1rem;
  animation: smoothUpDown 2s ease-in-out infinite; /* Animation applied to both images */
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(208, 208, 208, 0.757));

}

@keyframes smoothUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Move the images up */
  }
  100% {
    transform: translateY(0); /* Move the images back to original position */
  }
}


@media (max-width: 720px) {
  .goCvFirstSectionHeading h2 {
    font-size: 2.5rem;
  }
}
@media (max-width: 580px) {
  .goCvFirstSectionHeadingImg {
    display: block;
  }
  .goCvFirstSectionHeading h2 {
    font-size: 2rem;
  }
}
@media (max-width: 430px) {
  .goCvFirstSectionHeading h2 {
    font-size: 1.5rem;
  }
}
.GoCvWsection {
  max-width: 1640px;
  margin-right: auto;
  margin-left: auto;
}
#goCv2ndSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.goCv2ndSection {
  text-align: center;
  padding: 2rem 0px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}
.goCv2ndSection h2 {
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}
.goCv2ndSection img {
  max-width: 200px;
  width: 100%;
}
.goCv2ndSection section {
  max-width: 1000px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}
.goCv2ndSection section p {
  font-size: 1.2rem;
  background: linear-gradient(135deg, #46018399, #76014776);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;

}
@media (max-width: 767px) {
  #goCv2ndSection {
    flex-direction: column;
    gap: 10px;
  }
  .goCv2ndSection h2 {
    font-size: 2.5rem;
  }

}

@media (max-width: 450px) {
  .goCv2ndSection section p {
    font-size: 1rem;
  }
  .goCv2ndSection h2 {
    font-size: 2rem;
  }
}
.sponsorHeadin h2 {
  text-align: center;
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}
